export const HTTP_URL = 'http://';
export const HTTPS_URL = 'https://';

// Api Modes
export const apiModes = {
  test: 'test',
  regression: 'regression',
  live: 'live',
};

export const collectionRailList = [
  'MovieCollection',
  'MovieStarCollection',
  'SerieCollection',
  'CompetitionCollection',
];

// todo static values will remove,
export const irdetoContentId = 'G-XX-_A-ZMNALAJS1EP1__-240219221-9';
export const irdetoSessionTokenExpireDate =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjcxNDg1QkYwNDdDODQ4Njg5QkU4RUNDQjk0MUI1Q0RCIn0.eyJhaWQiOiJkaWdpdHVyayIsInN1YiI6IlUwRXhNekE0TkRJd05BIiwiaXNlIjp0cnVlLCJpc3MiOiIzMTY4LTIxLTYtMSIsImp0aSI6Ik1UTXdPRFF5TURSOE1UY3dPVFkxTlRVek1Yd3pNVFk0ZkRJeGZETyIsImlhdCI6MTcxNDY5NDQwMCwiZXhwIjoxOTA2MTE5MzIxLCJlbnQiOlt7ImJpZCI6IkJJRF9HLVhYLV9BLVpNTkFMQUpTMUVQMV9fLTI0MDIxOTIyMS05IiwiZXBpZCI6IlRPRF9hbGxUcmFja3Nfbm9IRENQIn1dfQ.FDTwFmNZPEyErxiH58iyasWkUuNdDKfMI_6BJnRzQs_IXm6SrCqkNnMvhYlxOYCCDVTu7k9UJSVRWvEzWioVYwoytd5brB5Ytig6W-R8itc_T8wEtsTDqRj8W41vYw2ATIJpqBY2X1-gyb6yP_YhtoE3guRxyfI9GqAF077hlHA_fOnH-BfOdRlcsZCyXKkHH7uhWESPFufvDNgVjLMmmIpPlFIwpmPnIqQl7YQpQbURQ2Fq0Qp73TwW5pkY0ADCag0Df_tUMzEeI7dSUa5sQJBzWlClWYx3tOKO5XeFkrk2v6Xs0a6cK-ooeAPHyTaY14kYFQbdqVuFnZ4muJDjIQ';
export const keyId = 'cd716370-caed-3555-a238-3b0b11c7a3f8';

export const certificateUrlForFairPlay =
  'https://digiturk.test.ott.irdeto.com/licenseServer/streaming/v1/digiturk/getcertificate?applicationId=app1';

export const videwineLicenceURL = `https://digiturk.test.ott.irdeto.com/licenseServer/widevine/v1/digiturk/license?contentId=`;
export const playreadyLicenceURL = `https://digiturk.test.ott.irdeto.com/licenseServer/playready/v1/digiturk/license?contentId=`;
export const fairplayLicenceURL = `digiturk.test.ott.irdeto.com/licenseServer/streaming/v1/digiturk/getckc?contentId=`;
// export const fairplayLicenceURL = `https://digiturk.test.ott.irdeto.com/licenseServer/streaming/v1/digiturk/getckc?contentId=${irdetoContentId}&keyId=${keyId}&ls_session=${irdetoSessionTokenExpireDate}`;
