import { StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * styles
 *
 * @returns {object} - styles
 */
const style = () => {
  return StyleSheet.create({
    container: {
      position: 'absolute',
      bottom: GetScaledValue(120),
      width: '100%',
      left: 0,
      right: 0,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
    },
  });
};

export default style;
