import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import useLocalization from '../../../libs/localization/useLocalization';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import FocusableSidebar from '../../templates/Sidebar';
import SearchView from '../../templates/SearchView';

import { IndexPropTypes } from './proptypes';
import styles from './styles';

/**
 * Search screen
 *
 * @param {object} props - props
 * @param {object} props.route - route
 * @returns {module:JSX.Element} - JSX.Element
 */
const Search = ({ route }) => {
  const [date, setDate] = useState(0);

  const navigation = useCustomNavigation();
  const { languageDirection } = useLocalization();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  useEffect(() => {
    if (route?.params?.date) {
      setDate(route?.params?.date);
    }
  }, [route]);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`search-page-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'search-page-content'}
        navigation={navigation}
      />
      <SearchView
        key={'search-page'}
        apiKey={'search-page'}
        prefix={'search-page'}
        focusKey={'search-page-content'}
        leftFocusKey={'sidebar'}
        initialFocus={'search-page-raillist-0'}
        navigation={navigation}
      />
    </View>
  );
};

Search.propTypes = IndexPropTypes;
Search.whyDidYouRender = false;

export default Search;
