import React, { useEffect } from 'react';
import Routes from './Routes';
import { useApp } from '../context';
import { setDirectionRTL } from '../helpers/FocusHelper';
import useLocalization from '../libs/localization/useLocalization';

/**
 * setup navigation for app or video screens
 *
 * @returns {Function} - function
 */
export default function setupNavigation() {
  /**
   * returns active navigator
   *
   * @returns {module:JSX.Element} - JSX.Element
   */
  const Root = () => {
    const { appState } = useApp();
    const { isRTL } = useLocalization();

    useEffect(() => {
      if (isRTL) {
        setDirectionRTL(isRTL);
      }
    }, [isRTL]);

    return appState.isInitiated && <Routes />;
  };

  return Root;
}
