import { StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ToolTipButton from '../../molecules/buttons/TooltipButton';
import { buttonSize, buttonThemes } from '../../molecules/buttons';
import { GetScaledValue } from '@digiturk/screen-size';
import SelectedButtonWithIcons from '../../molecules/buttons/SelectedButtonWithIcons';
import { DolbyAtmos, Settings } from '../../../assets/icons';
import { setFocusCustom } from '../../../helpers/FocusHelper';

const enums = {
  en_stereo: 'English Stereo',
  en_surround: 'English Surround',
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  tr: 'Turkish',
};

/**
 *
 * @param {object} props - props
 * @param {Array} props.data - data
 * @param {string} props.leftFocusKey - leftFocusKey
 * @param {boolean} props.userActivity - userActivity
 * @param {string} props.buttonPrefix - buttonPrefix
 * @param {string} props.downFocusKey - downFocusKey
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @returns {module:JSX.Element} - JSX.Element
 */
const ToolTipWrapper = ({
  data,
  leftFocusKey = false,
  userActivity,
  buttonPrefix,
  downFocusKey,
  subtitleSettingsCallback = () => {},
}) => {
  const [options, setOptions] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);

  const isOpen = options !== null;

  const filteredActionButtons = useMemo(
    () => data.filter((item) => item.isAvailable),
    [data]
  );

  const optionPosition =
    (filteredActionButtons.length - 1 - currentItem?.selectedIndex) *
    GetScaledValue(100);

  useEffect(() => {
    if (!userActivity) setOptions(null);
  }, [userActivity]);

  const onBecameFocused = useCallback((coordinate, selected) => {
    setCurrentItem({ coordinate, selectedIndex: selected.index });
  }, []);

  const onEnterPress = useCallback(
    (selectedButton) => {
      if (selectedButton.data) {
        options ? setOptions(null) : setOptions(selectedButton.data);
      } else {
        selectedButton.onSelectHandler();
      }
    },
    [options]
  );

  const onEnterOptionPress = useCallback(
    (option, index) => {
      if (option.id === 'settings') {
        subtitleSettingsCallback();
      } else {
        filteredActionButtons[currentItem?.selectedIndex]?.onSelectHandler(
          options[index]
        );
        selectedOptionIndex
          ? setSelectedOptionIndex(null)
          : setSelectedOptionIndex(index);

        setOptions(null);
        setFocusCustom(buttonPrefix + currentItem?.selectedIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentItem, filteredActionButtons, options, selectedOptionIndex]
  );

  const OptionContainer = useCallback(() => {
    if (!isOpen) return null;

    return (
      <View style={[styles.optionContainer, { end: optionPosition }]}>
        {options?.map((option, index) => (
          <SelectedButtonWithIcons
            key={index}
            text={enums[option.label] ?? option.label}
            size={buttonSize.big}
            theme={buttonThemes.transparent16}
            themeFocused={buttonThemes.primary}
            focusKey={`option-${index}`}
            style={{ width: GetScaledValue(420) }}
            upFocusKey={index === 0 ? false : `option-${index - 1}`}
            leftIcon={{
              icon: Settings,
              width: 30,
              height: 30,
            }}
            rightIcon={
              index < 2 && {
                icon: DolbyAtmos,
                width: 80,
                height: 32,
              }
            }
            isSelected={index === selectedOptionIndex}
            downFocusKey={
              index === options.length - 1
                ? buttonPrefix + currentItem.selectedIndex
                : `option-${index + 1}`
            }
            onEnterPress={() => onEnterOptionPress(option, index)}
          />
        ))}
      </View>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionPosition, currentItem, isOpen, options, selectedOptionIndex]);

  return (
    <View style={styles.buttonContainer}>
      {filteredActionButtons.map((button, index) => (
        <ToolTipButton
          key={index}
          index={index}
          icon={button.icon}
          text={button.label}
          isOpen={isOpen}
          size={buttonSize.xlarge}
          theme={buttonThemes.neutralQuaternary}
          themeFocused={buttonThemes.blackWhite}
          focusKey={buttonPrefix + index}
          onBecameFocused={onBecameFocused}
          style={{ marginStart: GetScaledValue(20) }}
          textAreaStyle={[styles.textAreaStyle]}
          upFocusKey={isOpen ? `option-${options?.length - 1}` : '4kButton'}
          downFocusKey={isOpen ? false : downFocusKey}
          rightFocusKey={
            index === button.length - 1
              ? false
              : !isOpen && buttonPrefix + (index + 1)
          }
          leftFocusKey={
            index === 0 ? leftFocusKey : !isOpen && buttonPrefix + (index - 1)
          }
          onEnterPress={() => onEnterPress(button)}
        />
      ))}
      <OptionContainer />
    </View>
  );
};

export default ToolTipWrapper;

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionContainer: {
    position: 'absolute',
    bottom: GetScaledValue(120),
    gap: GetScaledValue(8),
  },
  textAreaStyle: {
    position: 'absolute',
    top: GetScaledValue(-50),
    left: GetScaledValue(-60),
    alignItems: 'center',
    width: GetScaledValue(200),
  },
});
