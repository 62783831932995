import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Dev Mode Login page styles
 *
 * @returns {object} - styles
 */
const styles = StyleSheet.create({
  mainWrapper: {
    flex: 1,
    backgroundColor: colors.background,
    width: GetScaledValue(1920),
    height: GetScaledValue(100),
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 50,
  },
  container: {
    flexDirection: 'row',
    marginTop: 150,
    width: GetScaledValue(400),
    justifyContent: 'center',
    gap: GetScaledValue(300),
  },
  contentWrapper: {
    flex: 1,
    marginHorizontal: GetScaledValue(150),
    gap: GetScaledValue(40),
  },
  button: {
    width: GetScaledValue(300),
    height: GetScaledValue(80),
    alignSelf: 'center',
  },
});

export default styles;
