import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import useLocalization from '../../../libs/localization/useLocalization';
import PageContent from '../../templates/PageContent';
import FocusableSidebar from '../../templates/Sidebar';

import { IndexPropTypes } from './proptypes';
import styles from './styles';

/**
 * Creates a tv shows screen view
 *
 * @param {object} props - props
 * @param {object} props.route - route
 * @returns {module:JSX.Element} - JSX.Element
 */
const Sports = ({ route }) => {
  const [date, setDate] = useState(0);
  const { languageDirection } = useLocalization();
  const navigation = useCustomNavigation();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  useEffect(() => {
    if (route?.params?.date) {
      setDate(route?.params?.date);
    }
  }, [route]);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`sports-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'sports-content'}
        urlSlug={'sports'}
        navigation={navigation}
      />
      <PageContent
        key={'sports'}
        urlSlug={'sports'}
        prefix={'sports-raillist'}
        focusKey={'sports-content'}
        leftFocusKey={'sidebar'}
        navigation={navigation}
      />
    </View>
  );
};

Sports.propTypes = IndexPropTypes;
Sports.whyDidYouRender = false;

export default Sports;
