import React, { useEffect, useState } from 'react';
import { View, Linking, Image, ImageBackground } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import colors from '../../../helpers/Colors';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { ArrowLeftBold } from '../../../assets/icons';
import image from '../../../assets/images/png/FourKBackground/fourkbackground.png';
import Text from '../../atoms/Text';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';

import styles from './styles';
import { useMenu } from '../../../context';
import { getLink } from '../../../helpers/CommonHelper';
import { ApiRels } from '../../../helpers/Enums';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * FourKQR  component
 *
 * @param {Function} handleModal - handleModal
 * @returns {module:JSX.Element} - JSX.Element
 */
const FourKQR = ({ handleModal }) => {
  const [loginAuth, setLoginAuth] = useState('');
  const navigation = useCustomNavigation();
  const { menuState } = useMenu();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  useEffect(() => {
    setFocusCustom('backButton');
  }, []);

  /**
   * Handles the button press event.
   */
  const handlePress = async () => {
    const url = 'https://tod.tv/promo/4k';
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      console.error('Could not open the URL');
    }
  };

  // todo: headers will be dynamic

  useEffect(() => {
    (async () => {
      let generateAuthCodeUrl = '';

      if (menuState) {
        generateAuthCodeUrl = getLink(
          menuState.data,
          ApiRels.ACCOUNT.GENERATE_AUTH_CODE
        );
      }

      const response = await makeApiCall({
        url: generateAuthCodeUrl.url,
        method: 'POST',
        body: {
          loginDevice: {
            deviceId: '',
            userDeviceId: '',
            modelCode: '',
            manufacturerName: '',
            osType: '',
            osVersion: '',
            appVersion: '',
            deviceName: '',
            deviceType: '',
          },
        },
      });

      setLoginAuth(response);
      // .then(() => {
      //   setInterval(() => {
      //     fetch(
      //       'https://user.tod2-test.beiniz.biz/api/auth/login-with-auth-code',
      //       {
      //         method: 'POST',
      //         headers: headers,
      //         body: { authCode: { loginAuth.code} },
      //         redirect: 'follow',
      //       }
      //     )
      //       .then((response) => response.json())
      //       .then((result) => console.info(result))
      //       .catch((error) => console.error(error));
      //   }, 3000);
      // }, []);
    })();
  }, []);

  return (
    <View>
      <View style={styles.heroContainer}>
        <ImageBackground source={image} style={styles.image} />
        <LinearGradient
          colors={['rgba(0, 0, 0, 1)', 'rgba(0, 0, 0, 2)']}
          style={styles.gradient}
        />
      </View>
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <BasicButton
            icon={ArrowLeftBold}
            size={buttonSize.xlarge}
            theme={buttonThemes.neutral300}
            themeFocused={buttonThemes.neutral500}
            style={styles.backButton}
            focusKey={'backButton'}
            downFocusKey={'upgradeLink'}
            onEnterPress={() => {
              handleModal(null);
            }}
          />
        </View>
        <View style={styles.bottomContainer}>
          <View style={styles.upgradeText}>
            <Text
              text="Upgrade your subscription"
              color={colors.primary500}
              size="DS"
              bold={true}
            />
            <Text
              text="Sorry, your current subscription plan does not include access to 4K
            content."
              color={colors.neutral200}
              size="H2"
            />
          </View>
          <View style={styles.cardContainer}>
            <View style={styles.card}>
              <Text
                text="To view this video in 4K, please upgrade your subscription plan by visiting"
                numberOfLines={3}
                size="H2"
                color={colors.neutral100}
                style={styles.alignText}
              />
              <BasicButton
                text="https://tod.tv/promo/4k"
                style={styles.alignMid}
                size={buttonSize.big}
                theme={buttonThemes.transparent16}
                themeFocused={buttonThemes.neutralPrimary}
                focusKey={'upgradeLink'}
                upFocusKey={'backButton'}
                onEnterPress={() => {
                  handlePress();
                }}
              />
            </View>

            <Text
              text="OR"
              size="H3"
              color={colors.neutral400}
              style={styles.alignMid}
            />

            <View style={[styles.card, styles.card2]}>
              <Text
                text="Scan the QR code to upgrade your subscription"
                numberOfLines={3}
                size="H2"
                color={colors.neutral100}
                style={styles.alignText}
              />
              <Image
                source={{ uri: loginAuth?.imagePath }}
                style={styles.qrImage}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default FourKQR;
