import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Dev Mode Login page styles
 *
 * @returns {object} - styles
 */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    height: GetScaledValue(800),
    width: GetScaledValue(1000),
    gap: GetScaledValue(40),
  },
  innerContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: GetScaledValue(60),
  },
  inputContainer: {
    flex: 1,
    zIndex: 98,
    alignItems: 'center',
  },
  input: {
    width: GetScaledValue(520),
    height: GetScaledValue(100),
    borderRadius: GetScaledValue(15),
    borderWidth: GetScaledValue(2),
    justifyContent: 'center',
    paddingHorizontal: GetScaledValue(20),
  },
});

export default styles;
