import React, { memo, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import {
  Search,
  Home,
  Playlist,
  Sports,
  Movies,
  Series,
  LiveTV,
  Language,
  Settings,
  TODLogoPNG,
  PinWheel,
} from '../../../assets/icons';
import colors from '../../../helpers/Colors';
import Icon from '../../atoms/Icon';
import { MenuButton } from '../../molecules/buttons';
import PropList from './proptypes';
import styles from './styles';
import Colors from '../../../helpers/Colors';
import AvatarWithBorder from '../../molecules/AvatarWithBorder';
import LinearGradient from 'react-native-linear-gradient';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import useLocalization from '../../../libs/localization/useLocalization';
import { getLink, isAnimationsDisabled } from '../../../helpers/CommonHelper';
import { useMenu } from '../../../context';
import { ApiRels } from '../../../helpers/Enums';
import { useAuth } from '../../../context/Auth';
import SidebarSignIn from '../../molecules/SidebarSignIn';

/**
 * Icon List
 * Select icons by api iconName key
 */
const iconList = {
  search: Search,
  home: Home,
  sports: Sports,
  film: Movies,
  series: Series,
  'live-tv': LiveTV,
  playlist: Playlist,
  kids: PinWheel,
};

/**
 * Menu
 *
 * @param {object} props - props
 * @param {boolean} props.isSidebarOpened - isSidebarOpened
 * @param {string} props.rightFocusKey - rightFocusKey
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {string} props.urlSlug - urlSlug from api
 * @returns {module:JSX.Element} - JSX.Element
 */
const Menu = ({ isSidebarOpened, rightFocusKey, onEnterPress, urlSlug }) => {
  const prefix = 'sidebar-menu';
  const style = styles(isSidebarOpened);

  const { isRTL } = useLocalization();
  const { authState } = useAuth();
  const { menuState } = useMenu();

  const menuItems = menuState?.data?.items || [];

  const profileUrl = useMemo(
    () => getLink(menuState?.data, ApiRels.PROFILE.GET_PROFILES)?.url,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuState?.data]
  );

  const bottomMenuItems = [
    {
      id: 'LANGUAGE',
      title: 'Language',
      icon: Language,
    },
    {
      id: 'SETTINGS',
      title: 'Settings',
      icon: Settings,
      urlSlug: authState.at
        ? ApiRels.ACCOUNT.MY_ACCOUNT
        : ApiRels.ACCOUNT.LOGIN,
      url: getLink(menuState?.data, ApiRels.ACCOUNT.MY_ACCOUNT)?.url,
    },
  ];

  const TopIcon = useCallback(() => {
    if (!isSidebarOpened) {
      return (
        <View style={style.brand}>
          <Icon
            icon={TODLogoPNG}
            width={96}
            height={40}
            color={Colors.primary}
          />
        </View>
      );
    }

    if (authState?.at) {
      return (
        <View style={style.avatar}>
          <AvatarWithBorder
            id={'PROFILES'}
            url={profileUrl}
            focusKey={'avatar'}
            upFocusKey={false}
            onEnterPress={onEnterPress}
            downFocusKey={`${prefix}-1`}
            rightFocusKey={rightFocusKey}
          />
        </View>
      );
    } else {
      return (
        <SidebarSignIn
          id={'SIGN_IN'}
          focusKey={'sidebar-sign-in'}
          rightFocusKey={rightFocusKey}
          downFocusKey={`${prefix}-1`}
          onEnterPress={onEnterPress}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, isSidebarOpened, profileUrl]);

  return (
    <LinearGradient
      start={{ x: isRTL ? 1 : 0.35, y: 1 }}
      end={{ x: isRTL ? 0.35 : 1, y: 1 }}
      colors={[colors.background, colors.transparent]}
      style={style.external}>
      <View style={style.container}>
        <View style={style.topContainer}>
          <TopIcon />
        </View>
        <View style={style.outerContainer}>
          {menuItems.map((item, index) => (
            <MenuButton
              text={item.title}
              id={item.urlSlug}
              icon={iconList[item.iconName]}
              url={getLink(item, ApiRels.SELF)?.url}
              onlyIcon={!isSidebarOpened}
              selected={urlSlug === item.urlSlug && !isSidebarOpened}
              style={style.menuItem}
              key={`${prefix}-${index + 1}`}
              focusKey={`${prefix}-${index + 1}`}
              rightFocusKey={rightFocusKey ? rightFocusKey : false}
              upFocusKey={index === 0 ? 'avatar' : `${prefix}-${index}`}
              downFocusKey={
                index === menuItems.length - 1
                  ? `sidebar-bottom-0`
                  : `${prefix}-${index + 2}`
              }
              leftFocusKey={false}
              onEnterPress={onEnterPress}
              menu={item.urlSlug}
            />
          ))}
        </View>
        {isSidebarOpened && (
          <View style={style.bottomMenu}>
            {bottomMenuItems.map((item, index) => {
              return (
                <View
                  style={style.bottomMenuContainer}
                  key={`sidebar-bottom-${index}`}>
                  <MenuButton
                    text={item?.title}
                    id={item.id}
                    icon={item?.icon}
                    onlyIcon={false}
                    url={item.url}
                    selected={false}
                    style={style.menuItem}
                    focusKey={`sidebar-bottom-${index}`}
                    rightFocusKey={rightFocusKey ? rightFocusKey : false}
                    upFocusKey={
                      index === 0
                        ? `${prefix}-${menuItems.length}`
                        : `sidebar-bottom-${index - 1}`
                    }
                    downFocusKey={
                      index === bottomMenuItems.length - 1
                        ? false
                        : `sidebar-bottom-${index + 1}`
                    }
                    leftFocusKey={false}
                    onEnterPress={onEnterPress}
                    menu={item.urlSlug}
                  />
                </View>
              );
            })}
          </View>
        )}
      </View>
      {isSidebarOpened && (
        <Animated.View
          style={style.bigLogo}
          entering={!isAnimationsDisabled && FadeIn.duration(500)}
          exiting={!isAnimationsDisabled && FadeOut.duration(500)}>
          <Icon icon={TODLogoPNG} width={240} height={100} />
        </Animated.View>
      )}
    </LinearGradient>
  );
};

Menu.propTypes = PropList;
Menu.whyDidYouRender = false;

export default memo(Menu);
