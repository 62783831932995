import React, { createContext, useContext, useEffect, useReducer } from 'react';
import reducer from './reducer';
import { getStorage } from '../storage';
import storageKeys from '../storageKeys';
import actions from './actions';

const initialState = {
  profileState: {
    isInitiated: false,
    error: null,
  },
};

const Profile = createContext();

/**
 * Context Hook
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
export const useProfile = () => useContext(Profile);

/**
 * Profile Provider
 *
 * @param {object} props - props
 * @param {object} props.children - children
 * @returns {module:JSX.Element} - JSX.Element
 */
export const ProfileProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    (async () => {
      const selectedProfile = await getStorage(storageKeys.selectedProfile);

      if (selectedProfile && Object.keys(selectedProfile).length) {
        dispatch(actions.setSelectedProfile(selectedProfile));
      }

      dispatch(actions.init());
    })();
  }, []);

  const data = {
    ...state,
    profileDispatch: dispatch,
  };

  return <Profile.Provider value={data}>{children}</Profile.Provider>;
};
