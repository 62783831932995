import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { BasicButton } from '../buttons';
import { buttonThemes } from '../buttons';
import { GetScaledValue } from '@digiturk/screen-size';
import PropList from './proptypes';

/**
 * ButtonsGroup Component
 *
 * @param {object} props - props
 * @param {string} props.prefix - prefix
 * @param {any} props.buttons - item
 * @param {any} props.size - item
 * @param {object} props.containerStyle -container style
 * @returns {module:JSX.Element} - JSX.Element
 */
const ButtonsGroup = ({ prefix, buttons, containerStyle, size }) => {
  return (
    <View style={containerStyle}>
      {buttons.map((button, index) => {
        const downFocusKey =
          index === buttons.length - 1 ? false : `${prefix}-${index + 1}`;

        return (
          <BasicButton
            key={index}
            icon={button.icon}
            text={button.text}
            textTwo={button.textTwo}
            size={size}
            textAreaStyle={styles.textAreaStyle}
            focusKey={`${prefix}-${index}`}
            upFocusKey={index === 0 ? false : `${prefix}-${index - 1}`}
            downFocusKey={button.downFocusKey ?? downFocusKey}
            themeFocused={buttonThemes.primary}
            onEnterPress={button.onEnterPress}
            iconPosition={button.iconPosition}
            style={[styles.button, { ...button.style }]}
          />
        );
      })}
    </View>
  );
};

ButtonsGroup.propTypes = PropList;
ButtonsGroup.whyDidYouRender = false;

export default memo(ButtonsGroup);

const styles = StyleSheet.create({
  button: {
    marginBottom: GetScaledValue(8),
    width: GetScaledValue(520),
    height: GetScaledValue(74),
  },
  textAreaStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
