import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import useLocalization from '../../../libs/localization/useLocalization';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import FocusableSidebar from '../../templates/Sidebar';
import PageContent from '../../templates/PageContent';

import { IndexPropTypes } from './proptypes';
import styles from './styles';

/**
 * Creates a mylist screen view
 *
 * @param {object} props - props
 * @param {object} props.route - route
 * @returns {module:JSX.Element} - JSX.Element
 */
const Mylist = ({ route }) => {
  const [date, setDate] = useState(0);
  const { languageDirection } = useLocalization();
  const navigation = useCustomNavigation();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  useEffect(() => {
    if (route?.params?.date) {
      setDate(route?.params?.date);
    }
  }, [route]);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`mylist-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'mylist-content'}
        urlSlug={'my-list'}
        navigation={navigation}
      />
      <PageContent
        key={'mylist'}
        urlSlug={'my-list'}
        prefix={'mylist-raillist'}
        focusKey={'mylist-content'}
        leftFocusKey={'sidebar'}
        navigation={navigation}
      />
    </View>
  );
};

Mylist.propTypes = IndexPropTypes;
Mylist.whyDidYouRender = false;

export default Mylist;
