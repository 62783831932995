import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import useLocalization from '../../../libs/localization/useLocalization';
import AccountDetails from './AccountDetails';
import Subscription from './Subscription';
import Devices from './Devices';
import IconWithText from '../../molecules/IconWithText';
import { ArrowLeftBold, ArrowRightBold } from '../../../assets/icons';
import colors from '../../../helpers/Colors';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import Text from '../../atoms/Text';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { useRoute } from '@react-navigation/native';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * Settings Page
 *
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Settings = () => {
  const { url } = useRoute().params;
  const { isRTL, languageDirection } = useLocalization();
  const { navigate, goBack } = useCustomNavigation();

  const [data, setData] = useState([]);

  useHardwareBackPress(() => {
    goBack();
  }, true);

  useEffect(() => {
    setFocusCustom('Settings-SignOut');
  }, []);

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    const fetchData = async () => {
      const response = await makeApiCall({ url });

      response && setData(response.rails);
    };

    fetchData();
  }, [url]);

  const detail = useMemo(() => {
    return {
      account: data.filter((i) => i.railType === 'AccountDetails')[0],
      subscription: data.filter((i) => i.railType === 'Subscriptions')[0],
      devices: data.filter((i) => i.railType === 'Devices')[0],
    };
  }, [data]);

  const onEnterPress = useCallback(() => {
    const signOut = data.filter((i) => i.railType === 'SignOut')[0];

    navigate(NavigationRoutes.signOut, { data: signOut });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <View dir={languageDirection} style={styles.container}>
      <IconWithText
        containerStyle={styles.backButton}
        icon={isRTL ? ArrowRightBold : ArrowLeftBold}
        iconStyle={styles.iconStyle}
        text={'Back'}
        textStyle={styles.textStyle}
      />
      <View style={styles.topRow}>
        <AccountDetails account={detail.account} />
        <Subscription subs={detail.subscription} />
      </View>
      <View style={styles.bottomRow}>
        <Devices devices={detail.devices} />
      </View>
      <View style={styles.version}>
        <Text
          text={'All rights reserved © TOD 2024'}
          size={FONT_SIZES.HEADING5}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral300}
        />
        <Text
          text={'v2.0.2.4'}
          size={FONT_SIZES.HEADING5}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral300}
        />
      </View>
      <View style={styles.buttonArea}>
        <BasicButton
          text={'Sign Out'}
          size={buttonSize.big}
          theme={buttonThemes.neutral800}
          themeFocused={buttonThemes.primary}
          style={styles.buttonItem}
          textAreaStyle={styles.textAreaStyle}
          focusKey={'Settings-SignOut'}
          leftFocusKey={false}
          rightFocusKey={false}
          onEnterPress={onEnterPress}
        />
      </View>
    </View>
  );
};

export default Settings;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
    padding: GetScaledValue(80),
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: GetScaledValue(398),
    marginTop: GetScaledValue(60),
    gap: GetScaledValue(20),
  },
  bottomRow: {
    marginTop: GetScaledValue(20),
  },
  version: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: GetScaledValue(20),
  },
  iconStyle: {
    width: 40,
    height: 33,
    color: colors.shades00,
  },
  textStyle: {
    fontFamily: FONT_FAMILY.REGULAR,
    size: FONT_SIZES.HEADING3,
  },
  backButton: {
    flexDirection: 'row',
    gap: GetScaledValue(20),
    alignItems: 'center',
  },
  buttonArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: GetScaledValue(40),
    gap: GetScaledValue(20),
    justifyContent: 'center',
  },
  buttonItem: {
    width: GetScaledValue(400),
    alignItems: 'center',
    justifyContent: 'center',
  },
  textAreaStyle: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
