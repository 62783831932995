import { GetScaledValue } from '@digiturk/screen-size';
import React, { useMemo } from 'react';
import Animated, {
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';

/**
 * Animated component
 *
 * @param {object} props - props
 * @param {object} props.style - style
 * @param {object} props.globalFocusedItem - globalFocusedItem
 * @param {React.ReactNode} props.children - children
 * @returns {React.JSX.Element} - JSX.Element
 */
const AnimatedComponent = ({ style, globalFocusedItem, children }) => {
  const { railTop = 635 } = globalFocusedItem;

  const _railTop = useMemo(() => GetScaledValue(railTop), [railTop]);

  const animatedTop = useAnimatedStyle(() => {
    const paddingTop = withTiming(_railTop, { duration: 750 });

    return { paddingTop };
  }, [_railTop]);

  return <Animated.View style={[style, animatedTop]}>{children}</Animated.View>;
};

export default AnimatedComponent;
