import { View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PINCode from '../../molecules/PINCode';
import colors from '../../../helpers/Colors';
import { ArrowLeftBold, ArrowRightBold, Warning } from '../../../assets/icons';
import Text from '../../atoms/Text';
import {
  ApiRels,
  FONT_FAMILY,
  FONT_SIZES,
  ProfileTypes,
} from '../../../helpers/Enums';
import IconWithText from '../../molecules/IconWithText';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import styles from './styles';
import useLocalization from '../../../libs/localization/useLocalization';
import Numpad from '../../organisms/Numpad';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { useRoute } from '@react-navigation/native';
import BackgroundBlur from '../../atoms/BackgroundBlur';
import {
  menuActions,
  profileActions,
  useMenu,
  useProfile,
} from '../../../context';
import { getLink } from '../../../helpers/CommonHelper';
import { makeApiCall } from '../../../middleware/dynamic';
import { setAuth } from '../../../middleware/services/api';

/**
 *
 * @returns {module:JSX.Element} -
 */
const ProfileLogin = () => {
  const digitCount = 4;

  const [pin, setPin] = useState('');
  const [isWrong, setIsWrong] = useState(false);

  const { menuState, menuDispatch } = useMenu();
  const { currentProfileId, isEdit } = useRoute().params;

  const { isRTL } = useLocalization();
  const { navigate } = useCustomNavigation();

  const { profileState, profileDispatch } = useProfile();

  useHardwareBackPress(() => {
    navigate(NavigationRoutes.profiles, {}, 'profile-0');
  }, true);

  const currentProfile = useMemo(() => {
    return profileState?.profiles.find(
      (p) => p.userProfileId === currentProfileId
    );
  }, [profileState, currentProfileId]);

  const errorHandler = useCallback(() => {
    setIsWrong(true);
    const timeout = setTimeout(() => {
      setPin('');
      setIsWrong(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const verifyPinCode = useCallback(async () => {
    const { url, method } = getLink(
      currentProfile,
      ApiRels.PROFILE.VERIFY_PIN_CODE
    );

    const response = await makeApiCall({
      url,
      method,
      body: { pinCode: pin },
    });

    if (response) {
      setPin('');
      setIsWrong(false);
      navigate(NavigationRoutes.manageprofile, {
        isEdit: true,
        currentProfileId: currentProfile?.userProfileId,
      });
    } else errorHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProfile, pin]);

  const switchProfile = useCallback(() => {
    // eslint-disable-next-line require-jsdoc
    const postData = async () => {
      const { url, method } = getLink(
        currentProfile,
        ApiRels.PROFILE.SWITCH_PROFILE
      );

      const response = await makeApiCall({
        url,
        method,
        body: { pinCode: pin },
      });

      if (response) {
        setPin('');
        setIsWrong(false);
        setAuth(response.at);
        profileDispatch(profileActions.setSelectedProfile(currentProfile));

        if (currentProfile.profileType === ProfileTypes.KID) {
          const { url: kidMenu } = getLink(menuState.data, ApiRels.KIDS_MENU);
          const menus = await makeApiCall({ url: kidMenu });

          if (menus) {
            menuDispatch(menuActions.setMenu(menus));
            navigate(NavigationRoutes.kids, {}, 'kids-raillist-0');
          }
        } else {
          const { url: menu } = getLink(menuState.data, ApiRels.SELF);
          const menus = await makeApiCall({ url: menu });

          if (menus) {
            menuDispatch(menuActions.setMenu(menus));
            navigate(NavigationRoutes.home, {}, 'home-raillist-0');
          }
        }
      } else errorHandler();
    };

    postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProfile, pin]);

  useEffect(() => {
    if (pin.length === digitCount) {
      if (isEdit) verifyPinCode();
      else switchProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin, isEdit]);

  return (
    <View style={styles.container}>
      <View style={styles.zContainer}>
        <IconWithText
          containerStyle={styles.backButton}
          icon={isRTL ? ArrowRightBold : ArrowLeftBold}
          iconStyle={styles.iconStyle}
          text={'Back'}
          textStyle={styles.textStyle}
        />
        <View style={styles.headText}>
          <View style={styles.highligtedContainer}>
            <Text
              text={'Welcome Back'}
              fontFamily={FONT_FAMILY.SEMIBOLD}
              size={FONT_SIZES.HEADING1}
            />
            <Text
              text={currentProfile?.profileName}
              fontFamily={FONT_FAMILY.SEMIBOLD}
              size={FONT_SIZES.HEADING1}
              color={colors.primary500}
            />
          </View>
          {isWrong && (
            <IconWithText
              containerStyle={styles.alerContainer}
              icon={Warning}
              iconStyle={styles.iconStyle}
              text={'You have entered the PIN code incorrectly'}
              textStyle={styles.textStyle}
              color={colors.shades00}
            />
          )}
          <Text
            text={'Please enter your 4-digit PIN to access your profile'}
            fontFamily={FONT_FAMILY.REGULAR}
            size={FONT_SIZES.HEADING3}
            color={colors.neutral200}
          />
        </View>
        <PINCode
          pin={pin}
          hasTitle={false}
          isWrong={isWrong}
          digitCount={digitCount}
          containerStyle={styles.digitContainer}
        />
        <Numpad
          value={pin}
          limit={digitCount}
          setValue={setPin}
          prefix={'login-numpad'}
          containerStyle={styles.numpadContainer}
        />
      </View>
      <BackgroundBlur />
    </View>
  );
};

export default ProfileLogin;
