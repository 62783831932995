/* eslint-disable require-jsdoc */
import React from 'react';

import { Text, View } from 'react-native';
import styles from './style';

/**
 * Subtitle (Molecule)
 *
 * @returns {module:JSX.Element} - JSX.Element
 */

const Subtitle = ({ text, textStyle }) => {
  const style = styles();

  return (
    <View style={style.container}>
      <Text style={{ backgroundColor: 'black', color: 'yellow', fontSize: 40 }}>
        {text}
      </Text>
    </View>
  );
};

export default React.memo(Subtitle);
