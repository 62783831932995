export default {
  userData: 'TODMENA::USER_DATA',
  languageDirection: 'TODMENA::LANGUAGE_DIRECTION',
  profiles: 'TODMENA::PROFILES',
  selectedProfile: 'TODMENA::SELECTED_PROFILE',
  playerTimestamp: 'TODMENA::PLAYER_TIMESTAMP',
  playerTimestampExpiration: 'TODMENA::PLAYER_TIMESTAMP_EXPIRATION',
  auth: 'TODMENA::AUTH',
  apiMode: 'TODMENA::API_MODE',
};
