import { lazy } from 'react';

const Carousel = lazy(() => import('../../organisms/Carousel'));
const ComingSoon = lazy(() => import('../../organisms/ComingSoon'));
const FootballCompetitions = lazy(() =>
  import('../../organisms/CarouselDouble')
);
const Collections = lazy(() => import('../../organisms/Collections'));
const WrappedCarousel = lazy(() => import('../../organisms/WrappedCarousel'));

const railComponentsMap = {
  Default: Carousel,
  ComingSoon: ComingSoon,
  Collection: Collections,
  MyList: WrappedCarousel,
  ViewAll: WrappedCarousel,
  FootballCompetitions: FootballCompetitions,
};

export default railComponentsMap;
