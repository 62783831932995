import { StyleSheet, View } from 'react-native';
import React, { Suspense, useMemo, useState } from 'react';
import { windowHeight, windowWidth } from '../../../helpers/CommonHelper';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { GetScaledValue } from '@digiturk/screen-size';
import { ArrowUpStroke } from '../../../assets/icons';
import ArrowWithText from '../../molecules/ArrowWithText';
import colors from '../../../helpers/Colors';
import sectionComponentsMap from '../../molecules/SelectedComponent/Statistics';

const buttons = [
  {
    id: 0,
    type: 'Commentary',
    text: 'Commentary',
  },
  {
    id: 1,
    type: 'Highlights',
    text: 'Highlights',
  },
  {
    id: 2,
    type: 'Lineups',
    text: 'Lineups',
  },
  {
    id: 3,
    type: 'TeamStats',
    text: 'Team Stats',
  },

  {
    id: 4,
    type: 'TopPlayers',
    text: 'Top Players',
  },
];

// eslint-disable-next-line require-jsdoc
const SportsStatistics = ({ prefix, onSlideUp }) => {
  const [selectedTab, setSelectedTab] = useState('Commentary');

  const SelectedComponent = useMemo(
    () => sectionComponentsMap[selectedTab] || sectionComponentsMap.default,
    [selectedTab]
  );

  return (
    <View style={styles.container}>
      <ArrowWithText
        icon={ArrowUpStroke}
        text="Match"
        iconStyle={styles.icon}
      />
      <View style={styles.buttonContainer}>
        {buttons.map((button, index) => (
          <BasicButton
            key={index}
            text={button.text}
            size={buttonSize.big}
            theme={buttonThemes.transparent16}
            themeFocused={buttonThemes.primary}
            style={styles.buttonItem}
            focusKey={prefix + index}
            rightFocusKey={
              index === buttons.length - 1 ? false : prefix + (index + 1)
            }
            leftFocusKey={index === 0 ? false : prefix + (index - 1)}
            downFocusKey={prefix + selectedTab + '-0'}
            upFocusKey={onSlideUp}
            onEnterPress={() => setSelectedTab(button.type)}
          />
        ))}
      </View>
      <View style={styles.innerContainer}>
        <Suspense>
          <SelectedComponent
            prefix={prefix + selectedTab + '-'}
            upFocusKey={
              prefix + buttons.findIndex((b) => b.type === selectedTab)
            }
          />
        </Suspense>
      </View>
    </View>
  );
};

export default SportsStatistics;

const styles = StyleSheet.create({
  container: {
    width: windowWidth,
    height: windowHeight,
    paddingTop: GetScaledValue(60),
    paddingStart: GetScaledValue(80),
    backgroundColor: 'black',
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: GetScaledValue(20),
    marginTop: GetScaledValue(60),
  },
  icon: {
    width: 28,
    height: 40,
    color: colors.shades00,
  },
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: GetScaledValue(40),
    width: GetScaledValue(840),
    height: GetScaledValue(790),
  },
});
