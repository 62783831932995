import { View } from 'react-native';
import React, { useMemo } from 'react';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Deanimated component
 *
 * @param {object} props - props
 * @param {object} props.style - style
 * @param {number} props.globalFocusedItem - globalFocusedItem
 * @param {React.ReactNode} props.children - children
 * @returns {React.JSX.Element} - JSX.Element
 */
const Bare = ({ style, globalFocusedItem = {}, children }) => {
  const { railTop = 635 } = globalFocusedItem;

  const paddingTop = useMemo(() => GetScaledValue(railTop), [railTop]);

  return <View style={[style, { paddingTop }]}>{children}</View>;
};

export default Bare;
