const EventManager = {
  list: new Map(),

  /**
   * Emit event
   *
   * @param {string} eventType - event type
   * @param {any} args - arguments
   */
  emit(eventType, ...args) {
    const callback = this.list.get(eventType);

    if (callback) {
      callback.apply(null, args);
    }
  },

  /**
   * On event
   *
   * @param {string} eventType - event type
   * @param {Function} eventAction - event action
   * @returns {object} - this
   */
  on(eventType, eventAction) {
    this.list.set(eventType, eventAction);

    return this;
  },

  /**
   * Remove event listener
   *
   * @param {string} eventType - event type
   * @returns {object} - this
   */
  off(eventType) {
    this.list.delete(eventType);

    return this;
  },
};

export default EventManager;
