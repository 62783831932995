import axios from 'axios';
import env from '../../middleware/services/config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { encryptKey } from '../../libs/encryptKey';
import { getApiMode } from '../../helpers/CommonHelper';

/**
 * Get API Key
 *
 * @param {object} request - request
 * @param {object} api - api axios object
 * @returns {object} - error response
 */
const getApiKey = async (request, api) => {
  const apiMode = await getApiMode();

  const dune = `${env.baseUrls[apiMode].api}api/v${env.baseUrls[apiMode].apiVersion}/harkonnen`;
  const response = await axios.get(dune, { headers: api.defaults.headers });

  if (response) {
    let key = [...response.data].reverse().join('');

    key = key.substring(3, key.length - 3);
    await AsyncStorage.setItem('arrakis', key);
    request.headers['accept-digi'] = encryptKey(key);
  } else {
    console.error('Error getting Harkonnen key');
  }
};

/**
 * Encrypt API Key
 *
 * @param {object} request - request
 * @param {object} key - accept-digi key
 * @returns {object} - error response
 */
const encryptApiKey = async (request, key) => {
  request.headers['accept-digi'] = encryptKey(key);

  return request;
};

/**
 * API Key Handler
 *
 * @param {object} request - request
 * @param {object} api - api axios object
 * @returns {object} - error response
 */
const apiKeyHandler = async (request, api) => {
  const acceptDigiKey = await AsyncStorage.getItem('arrakis');

  if (!acceptDigiKey) {
    await getApiKey(request, api);
  } else {
    request = await encryptApiKey(request, acceptDigiKey);
  }

  return request;
};

export { getApiKey, apiKeyHandler };
