import { ScrollView, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  isAnimationsDisabled,
  isWeb,
  windowHeight,
  windowWidth,
} from '../../../helpers/CommonHelper';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import { useFocusEffect, useRoute } from '@react-navigation/native';

import PlayerWidget from '../../molecules/PlayerWidget';
import HiddenFocusPlaceHolder from './hiddenFocusPlaceHolder';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import LivePlayerMenuProperties from '../../templates/LivePlayerMenuProperties';
import { VIDEO_MODES } from '../../../helpers/Enums';
import SportsStatistics from '../../templates/SportsStatistics';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import colors from '../../../helpers/Colors';
import EventManager from '../../../helpers/EventEmitter';

const ACTIVITY_TIMEOUT = 5000;

const sampleContent = {
  audios: ['English', 'Arabic'],
  contentType: { links: [[Object]], name: 'FILM', type: 1, vodType: 'LIVE' },
  duration: 119,
  genres: ['Thriller', 'Action', 'Crime'],
  id: 637649,
  image: {
    hero_16x9:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
    hero_2x3:
      'https://image.tmdb.org/t/p/original/M7SUK85sKjaStg4TKhlAVyGlz3.jpg',
    hero_3x1:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
    hero_3x3:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
    hero_7x3:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
  },
  links: [
    {
      href: 'https://caladan.tod2-test.beiniz.biz/api/v2/contents/films/wrath-of-man',
      method: 'GET',
      rel: 'self',
    },
  ],
  railType: 'ContentFilmDetail',
  rating: '7.6',
  releaseDate: 2021,
  subtitles: ['English', 'Arabic', 'French'],
  summary:
    "A cold and mysterious new security guard for a Los Angeles cash truck company surprises his co-workers when he unleashes precision skills during a heist. The crew is left wondering who he is and where he came from. Soon, the marksman's ultimate motive becomes clear as he takes dramatic and irrevocable steps to settle a score.",
  title: 'Wrath of Man',
  titleOriginal: 'Wrath of Man',
  urlSlug: 'films/wrath-of-man',
  video: {
    dash: 'https://dash.akamaized.net/dash264/TestCases/1b/qualcomm/1/MultiRatePatched.mpd',
    id: 637649,
    poster:
      'https://image.tmdb.org/t/p/original/70AV2Xx5FQYj20labp0EGdbjI6E.jpg',
    url: 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
  },
};

const source = {
  dash: 'https://bein-tod-live.akamaized.net/Content/DASH_DASH/Live/channel(Digiturktest)/manifest.mpd',
  hls: 'https://bein-tod-live.akamaized.net/Content/HLS_HLSv4/Live/channel(Digiturktest)/index.m3u8',
};

/**
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Live = () => {
  const tabPrefix = 'statistic-button-';
  const refPlayer = useRef();
  const scrollRef = useRef();
  const activityTimeout = useRef();

  const { selectedItem: contentLive } = useRoute().params;

  const [audioTracks, setAudioTracks] = useState(null);
  const [playerCurrentTime, setPlayerCurrentTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isTrackLive, setIsTrackLive] = useState(true);
  const [userActivity, setUserActivity] = useState(true);
  const [currentFocus, setCurrentFocus] = useState('');
  const [isLongPressActive, setIsLongPressActive] = useState(false);

  const navigation = useCustomNavigation();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  useEffect(() => {
    EventManager.on('focuschanged', ({ focusKey }) => {
      setCurrentFocus(focusKey);
      setUserActivity(true);
    });

    EventManager.on('longpress', (data) => {
      setIsLongPressActive(data);
    });
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (currentFocus !== 'hidden-focus-placeholder') {
        activityTimeout.current = setTimeout(() => {
          if (!isLongPressActive) {
            setUserActivity(false);
          }
        }, ACTIVITY_TIMEOUT);
      } else setUserActivity(false);

      return () => {
        clearTimeout(activityTimeout.current);
      };
    }, [currentFocus, isLongPressActive])
  );

  const playPauseToggle = useCallback(() => {
    if (!isPaused) refPlayer.current.pause();
    else refPlayer.current.play();
  }, [isPaused]);

  /**
   * On load handler player - set player, audio,total time and subtitle tracks
   *
   * @param {object} player - player
   */
  const onLoadHandler = useCallback(async (_player) => {
    if (!_player) return;

    refPlayer.current = _player;

    setAudioTrackHandler(_player);
    await _player.timeShift(0);
    !_player.isPlaying() && _player.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Set audio track - For web and native loads different audio tracks
   *
   * @param {object} _player - player
   */
  const setAudioTrackHandler = useCallback((_player) => {
    if (!isWeb) {
      (async () => {
        const availableAudioTracks = await _player.getAvailableAudioTracks();

        setAudioTracks(availableAudioTracks);
      })();
    } else setAudioTracks(_player.getAvailableAudio());
  }, []);

  /**
   * On current time update handler - callback player current time calls from atoms/player
   *
   * @param {object} TimeChangedEvent - time changed event
   */
  const onCurrentTimeUpdateHandler = useCallback(async () => {
    const ct = await refPlayer.current.getTimeShift();
    const duration = await refPlayer.current.getMaxTimeShift();

    setIsPaused(false);
    setTotalTime(-duration);
    setPlayerCurrentTime(Math.floor(ct - duration));
    if (ct < 0) setIsTrackLive(false);
    else setIsTrackLive(true);
  }, []);

  /**
   * On enter press handler for Progress bar
   * - play pause callback
   * - set focus to progress bar
   *
   * @returns {void}
   */
  const onEnterPressHandlerProgressBar = useCallback(() => {
    setIsPaused(!isPaused);
    playPauseToggle();
  }, [isPaused, playPauseToggle]);

  const onEnterPressHandlerHiddenFocusPlaceholder = useCallback(() => {
    setFocusCustom('progress-bar');
    setUserActivity(!userActivity);
    setIsPaused(!isPaused);
    playPauseToggle();
  }, [isPaused, userActivity, playPauseToggle]);

  const activityHandlerWithFocus = useCallback(() => {
    setUserActivity(true);
    setFocusCustom('progress-bar');
  }, []);

  const onSlideDown = useCallback(() => {
    setUserActivity(false);
    scrollRef.current.scrollToEnd();

    setFocusCustom(tabPrefix + 0);
  }, []);

  const onSlideUp = useCallback(() => {
    setUserActivity(true);
    scrollRef.current.scrollTo({ x: 0, y: 0, animated: !isAnimationsDisabled });

    setFocusCustom('progress-bar');
  }, []);

  return (
    <ScrollView ref={scrollRef} style={styles.container} scrollEnabled={false}>
      <View style={styles.playerContainer}>
        <PlayerWidget
          onLoad={onLoadHandler}
          onCurrentTimeUpdate={onCurrentTimeUpdateHandler}
          refPlayer={refPlayer}
          content={sampleContent}
          source={source}
          isStatisticsView={currentFocus.startsWith(tabPrefix)}
          videoType={contentLive.contentStatusType}
        />

        <HiddenFocusPlaceHolder
          focusKey={'hidden-focus-placeholder'}
          upFocusKey={activityHandlerWithFocus}
          downFocusKey={activityHandlerWithFocus}
          rightFocusKey={activityHandlerWithFocus}
          leftFocusKey={activityHandlerWithFocus}
          onEnterPress={onEnterPressHandlerHiddenFocusPlaceholder}
        />

        <LivePlayerMenuProperties
          videoType={VIDEO_MODES.LIVE}
          currentTime={playerCurrentTime}
          contentVod={sampleContent}
          isPaused={isPaused}
          refPlayer={refPlayer}
          userActivity={userActivity}
          totalTime={totalTime}
          audioList={audioTracks}
          isTrackLive={isTrackLive}
          showStatistics={false}
          onEnterPress={onEnterPressHandlerProgressBar}
          onSlideDown={onSlideDown}
          isLongPressActive={isLongPressActive}
        />
      </View>

      <SportsStatistics onSlideUp={onSlideUp} prefix={tabPrefix} />
    </ScrollView>
  );
};

export default withFocusable({ trackChildren: true })(Live);

const styles = StyleSheet.create({
  container: {
    width: windowWidth,
    height: windowHeight,
    backgroundColor: colors.shades100,
  },
  playerContainer: {
    width: windowWidth,
    height: windowHeight,
    zIndex: 99,
  },
});
