import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, Image, ImageBackground, FlatList } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import colors from '../../../helpers/Colors';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import api from '../../../middleware/services/api';
import { ArrowLeftBold, Loading } from '../../../assets/icons';
import image from '../../../assets/images/png/FourKBackground/fourkbackground.png';
import Icon from '../../atoms/Icon';
import Text from '../../atoms/Text';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import { makeApiCall } from '../../../middleware/dynamic';

import styles from './styles';
import { ApiRels, FONT_SIZES } from '../../../helpers/Enums';
import { setAuth } from '../../../middleware/services/api';
import { getLink } from '../../../helpers/CommonHelper';

import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { authActions, useAuth } from '../../../context/Auth';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { profileActions, useMenu, useProfile } from '../../../context';

/**
 * SignIn  component
 *
 * @param {Function} handleModal - handleModal
 * @returns {module:JSX.Element} - JSX.Element
 */
const SignIn = ({ handleModal }) => {
  const TIME_OUT = 180000;
  const REQUEST_INTERVAL = 5000;

  const { menuState } = useMenu();
  const { profileDispatch } = useProfile();

  const [loginAuth, setLoginAuth] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [isGenerating, setIsGenerating] = useState(true);
  const [hasError] = useState(false);
  const [pinCodes, setPinCodes] = useState([]);

  const timerRef = useRef(null);
  const authInterval = useRef(null);

  const { goBack, replaceNavigate } = useCustomNavigation();
  const { authDispatch } = useAuth();

  useHardwareBackPress(() => {
    goBack();
  }, true);

  useEffect(() => {
    setFocusCustom('renewButton');
  }, []);

  useEffect(() => {
    loginHandler();

    return () => {
      clearInterval(authInterval.current);
    };
  }, [loginHandler]);

  const loginHandler = useCallback(async () => {
    let generateAuthCodeUrl;

    if (menuState) {
      generateAuthCodeUrl = getLink(
        menuState.data,
        ApiRels.ACCOUNT.GENERATE_AUTH_CODE
      );
    }

    const response = await makeApiCall({
      url: generateAuthCodeUrl.url,
      method: 'POST',
      body: {
        loginDevice: {
          deviceId: '',
          userDeviceId: '',
          modelCode: '',
          manufacturerName: '',
          osType: '',
          osVersion: '',
          appVersion: '',
          deviceName: '',
          deviceType: '',
        },
      },
    });

    const splittedCode = response.code.split('');
    let data = [];

    splittedCode.map((e) => {
      data.push({ value: e });
    });

    setPinCodes(data);
    setIsGenerating(false);
    setLoginAuth(response);
    loginWithAuthCode(response, response.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState]);

  const loginWithAuthCode = useCallback(
    async (generateWithAuthCodeRes, authCode) => {
      let loginWithAuthCodeURL;

      if (generateWithAuthCodeRes) {
        loginWithAuthCodeURL = getLink(
          generateWithAuthCodeRes,
          ApiRels.ACCOUNT.LOGIN_WITH_AUTH_CODE
        );
      }

      if (authCode) {
        authInterval.current = setInterval(async () => {
          const response = await makeApiCall({
            url: loginWithAuthCodeURL.url,
            method: 'POST',
            headers: api.defaults.headers,
            body: { authCode: authCode },
          });

          if (response.at) {
            setAuth(response.at);
            authDispatch(
              authActions.setAuth({ at: response.at, rt: response.rt })
            );

            const { url: nextLink } = getLink(
              response,
              ApiRels.PROFILE.GET_PROFILES
            );

            const profies = await makeApiCall({ url: nextLink });

            if (profies) {
              profileDispatch(profileActions.setProfiles(profies));
            }

            replaceNavigate(NavigationRoutes.profiles);
          }
        }, REQUEST_INTERVAL);
      }
    },
    [authDispatch, profileDispatch, replaceNavigate]
  );

  useEffect(() => {
    if (!isExpired && loginAuth) {
      timerRef.current = setTimeout(() => {
        setIsExpired(true);
      }, TIME_OUT);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [loginAuth, isExpired]);

  /**
   * Render Item
   *
   * @param {object} props - props
   * @param {object} props.item - item
   * @returns {module:JSX.Element} - JSX.Element
   */
  const renderItem = ({ item }) => {
    return (
      <View style={styles.flatListContainer}>
        <Text text={item.value} size={FONT_SIZES.DISPLAYSMALL} />
      </View>
    );
  };

  /**
   * Renew QR Code
   */
  const handleRenewCode = () => {
    setIsExpired(false);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    loginHandler();
  };

  return (
    <View>
      <View style={styles.heroContainer}>
        <ImageBackground source={image} style={styles.image} />
        <LinearGradient
          colors={[colors.blackTransparent01, colors.blackTransparent04]}
          style={styles.gradient}
        />
      </View>
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <BasicButton
            icon={ArrowLeftBold}
            text="Back"
            size={buttonSize.xlarge}
            theme={buttonThemes.ghostWhite}
            themeFocused={buttonThemes.ghostWhite}
            style={styles.backButton}
            focusKey={'backButton'}
            onEnterPress={() => handleModal(null)}
          />
        </View>
        <View style={styles.bottomContainer}>
          <View style={styles.signInText}>
            <Text
              text="Sign in to explore"
              color={colors.primary500}
              size="DS"
              bold={true}
            />
            <Text
              text="Welcome to TOD! Unlimited movies, TV shows, and more. Watch anywhere. Cancel anytime."
              color={colors.neutral200}
              size="H2"
            />
          </View>

          {isGenerating ? (
            <View style={styles.containerCard}>
              <View>
                <Icon
                  icon={Loading}
                  width={72}
                  height={72}
                  fill={colors.shades100}
                />
              </View>
              <Text
                text="Generating the new code..."
                size={FONT_SIZES.HEADING2}
              />
            </View>
          ) : hasError ? (
            <View style={styles.containerCard}>
              <Text
                text="An error occurred. Please try again."
                size={FONT_SIZES.HEADING2}
              />
              <View>
                <BasicButton
                  style={styles.renewButton}
                  text="Renew Code"
                  focusKey={'renewButton'}
                  theme={buttonThemes.primary}
                  size={buttonSize.xlarge}
                  onEnterPress={() => {
                    handleRenewCode();
                  }}
                />
              </View>
            </View>
          ) : isExpired ? (
            <View style={styles.containerCard}>
              <Text
                text="Activation code has expired. Please generate a new code."
                size={FONT_SIZES.HEADING2}
              />
              <BasicButton
                style={styles.renewButton}
                text="Renew Code"
                focusKey={'renewButton'}
                theme={buttonThemes.primary}
                size={buttonSize.xlarge}
                onEnterPress={() => {
                  handleRenewCode();
                }}
              />
            </View>
          ) : (
            <>
              <View>
                <View style={styles.cardContainer}>
                  <View style={styles.card}>
                    <View style={styles.leftCardContainer}>
                      <Text
                        text="To watch on your TV, go to the URL and enter the code below"
                        numberOfLines={3}
                        size={FONT_SIZES.HEADING2}
                        color={colors.neutral100}
                        style={styles.alignText}
                      />
                      <View style={styles.leftCardBottom}>
                        <Text
                          text="https://tod.tv/tvcode"
                          numberOfLines={3}
                          size="H2"
                          color={colors.neutral100}
                          style={styles.linkText}
                        />
                        <View style={styles.flatlist}>
                          <FlatList
                            data={pinCodes}
                            renderItem={renderItem}
                            keyExtractor={(item, index) => index}
                            horizontal={true}
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  <Text
                    text="OR"
                    size="H3"
                    color={colors.neutral400}
                    style={styles.alignMid}
                  />

                  <View style={[styles.card, styles.card2]}>
                    <Text
                      text="Scan the QR code to Sign In"
                      numberOfLines={3}
                      size="H2"
                      color={colors.neutral100}
                      style={styles.alignText}
                    />
                    <Image
                      source={{ uri: loginAuth?.imagePath }}
                      style={styles.qrImage}
                    />
                  </View>
                </View>
              </View>
            </>
          )}
        </View>
        <View style={styles.copyright}>
          <Text
            text="All rights reserved © TOD 2024"
            size={FONT_SIZES.HEADING5}
            color={colors.neutral100}
          />
          <Text
            text="v1.0.2"
            size={FONT_SIZES.HEADING5}
            color={colors.neutral100}
          />
        </View>
      </View>
    </View>
  );
};

export default SignIn;
