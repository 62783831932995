/* eslint-disable jsdoc/check-param-names*/
/* eslint-disable no-use-before-define */
import React, { useCallback, useRef } from 'react';
import { PlayerPropTypes, PlayerDefaultProps } from './proptypes';
import { useFocusEffect } from '@react-navigation/native';
import { Player, PlayerEvent } from 'bitmovin-player';
import {
  playreadyLicenceURL,
  videwineLicenceURL,
} from '../../../helpers/Constants';
import { AsidController } from '../../../libs/fmts/asid';
/**
 * Player component
 *
 * @param {Function} onLoad - on load callback
 * @param {object} props.onCurrentTimeUpdate - on current time update callback -> currentTime: number
 * @param {object} props.onDurationChange - on duration change callback -> seekableDuration: number
 * @param {object} props.source - player source
 * @returns {module:JSX.Element} - player html
 */
const LiveToVodPlayer = ({ onLoad, onCurrentTimeUpdate, source }) => {
  const playerConfig = {
    key: '047DDDE8-7D3F-4355-959A-4DC51EC5B10E',
    ui: false,
  };
  const timeout = useRef();

  console.info('  >>>>>>>>> source live', source);
  const playerSource = {
    dash: source.dash,
    hls: source.hls,
    poster:
      'https://bitdash-a.akamaihd.net/content/MI201109210084_1/poster.jpg',
    drm: {
      // widevine licence test for chrome browser or chromium base browser
      widevine: {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/base64',
          Authorization: 'Bearer ' + encodeURIComponent(source?.ticket),
        },
        LA_URL: `${videwineLicenceURL}${source?.mediaId}`,
        prepareMessage: (keyMessage) => {
          console.info('Widevine drm message: ' + JSON.stringify(keyMessage));

          return keyMessage.message;
        },
      },
      // playready licence test for microsoft edge browser
      playready: {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/base64',
          Authorization: 'Bearer ' + encodeURIComponent(source?.ticket),
        },
        LA_URL: `${playreadyLicenceURL}${source?.mediaId}&ls_session=${source?.ticket}`,
        prepareMessage: (keyMessage) => {
          console.info('Playready drm message: ' + JSON.stringify(keyMessage));

          return keyMessage.message;
        },
      },
    },
  };

  /**
   * İnitialize Player
   */
  const initializePlayer = useCallback(() => {
    const player = new Player(document.getElementById('player'), playerConfig);
    var playerCheck = null;

    player.load(playerSource).then(() => {
      /**
       *  FMTS Source config
       *
       *  @param {string} e - e
       *  @returns {object} - config
       */
      const sourceConfig = (e) => {
        console.info(e);
        let _fmts = JSON.parse(source?.fmts);

        _fmts?.su && _fmts?.su.slice(-1) === '/' && _fmts?.su.slice(0, -1);
        console.info('>>>>>> fmts filter', _fmts);

        return Promise.resolve(
          JSON.stringify(_fmts)
          // JSON.stringify({
          //   md: 'symmetric',
          //   su: 'https://tod.asidintegration.fmtsapi.com',
          //   at: '04f18697915b072f66ae3cbf71b10058xr9IwCGEasO%2Fc5UsT%2B6HHKgcLn4ZyyfcRBNynWIcOr2J8KkoVNnFB7mpE%2Ba2ZZ%2By1oGp2HSvlwPHWDsG14e366sjr9Ev1khpx7gBhhGOQ1kmkEKm2DSuyuXSnRlcDS%2FjZAb6VxWSK1i2ZifPKMUTpQ%3D%3D',
          // })

          // JSON.stringify({
          //   at: source?.fmts,
          //   su: 'https://asidintegration.fmtsapi.com',
          //   md: 'symmetric',
          //   cd: null,
          // })
        );
      };

      /**
       *
       * defined logger
       */
      const logger = () => {};

      // AsidIntegrationLogger.setLogCallback(console.info, 'DCzmXa6VqqFw48Wu');

      /**
       *
       * @param {Function} _playerCheck - function
       */
      function terminateCallback(_playerCheck) {
        console.info('_playerCheck', _playerCheck);
        // _playerCheck.terminateExistingSession();
      }

      player.on(PlayerEvent.Ready, async function (event) {
        if (playerCheck === null) {
          console.info('asid controller start');
          (playerCheck = new AsidController()).setTamperCallback(logger),
            playerCheck.setTerminateCallback(terminateCallback),
            playerCheck.setDecryptionHandler(sourceConfig),
            Math.floor(1e3 * player.getCurrentTime());
          try {
            await playerCheck.startWithDomVideo(
              {
                initToken: source?.fmts,
                validationKey: '<Your-Key>',
                failureRecallFrequency: '',
                appName: 'Bitmovin Example',
                appVersion: '2.0.0',
                appIdentifier: 'dev',
                assetTitle: 'More Cowbell',
                naturalVideoWidth: 800,
                naturalVideoHeight: 600,
              },
              'bitmovinplayer-video-player-for-video-screen'
            );
          } catch (e) {
            console.error(e);
          }
        }

        timeout.current = setTimeout(() => {
          player.play();
        }, 1500);
      });
    });

    player.on(PlayerEvent.TimeChanged, (event) => {
      onCurrentTimeUpdate && onCurrentTimeUpdate(event);
    });

    player.on(PlayerEvent.SourceLoaded, (event) => {
      console.info('Source Loaded', event);
      onLoad && onLoad(player);
    });

    player.on(PlayerEvent.SourceUnloaded, (error) => {
      console.info('Source Unloaded', error);
    });

    player.on(PlayerEvent.Destroy, (error) => {
      console.info('Destroyed Vod', error);
    });

    player.on(PlayerEvent.Error, (event) => {
      console.info('PlayerEvent Error', event);
    });

    player.on(PlayerEvent.CastStart, (event) => {
      console.info('PlayerEvent CastStart', event);
    });

    player.on(PlayerEvent.Ready, (event) => {
      console.info('Ready', event);
      player?.play();
    });

    return () => {
      player?.destroy();
    };
  }, []);

  useFocusEffect(initializePlayer);

  return <div id={'player'} />;
};

LiveToVodPlayer.propTypes = PlayerPropTypes;
LiveToVodPlayer.defaultProps = PlayerDefaultProps;

export default LiveToVodPlayer;
