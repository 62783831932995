import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Live Player page styles
 *
 * @returns {object} - styles
 */
const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.shades100,
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    height: GetScaledValue(40),
    paddingTop: GetScaledValue(10),
  },
  description: {
    fontSize: 16,
    marginTop: GetScaledValue(35),
    lineHeight: GetScaledValue(40),
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: GetScaledValue(60),
    marginStart: GetScaledValue(60),
  },
  icon: {
    width: GetScaledValue(21),
    height: GetScaledValue(20),
  },
  textContainer: {
    height: GetScaledValue(800),
    width: GetScaledValue(800),
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: GetScaledValue(20),
    marginStart: GetScaledValue(60),
  },
  buttonContainers: {
    display: 'flex',
    flexDirection: 'row',
    gap: GetScaledValue(20),
    marginTop: GetScaledValue(20),
  },
  image: {
    width: GetScaledValue(800),
    height: GetScaledValue(900),
    marginTop: GetScaledValue(100),
    transform: [{ rotate: '10deg' }],
  },
  button: {
    width: GetScaledValue(320),
    height: GetScaledValue(60),
    borderRadius: GetScaledValue(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
