import { StyleSheet, View } from 'react-native';
import React, { useMemo } from 'react';
import Icon from '../../atoms/Icon';
import colors from '../../../helpers/Colors';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { GetScaledValue } from '@digiturk/screen-size';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import Text from '../../atoms/Text';
import { UserStroke } from '../../../assets/icons';

/**
 * Sidebar sign in component
 *
 * @param {object} props - props
 * @param {string} props.focused - focused
 * @returns {module:JSX.Element} - JSX Element
 */
const SidebarSignIn = ({ focused }) => {
  const color = useMemo(
    () => (focused ? colors.primary : colors.neutral300),
    [focused]
  );

  return (
    <View style={styles.container}>
      <Icon icon={UserStroke} width={40} height={40} color={color} />

      <Text
        text={'Sign In | Subscribe'}
        fontFamily={FONT_FAMILY.SEMIBOLD}
        size={FONT_SIZES.HEADING2}
        color={color}
      />
    </View>
  );
};

export default withFocusable()(SidebarSignIn);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: GetScaledValue(80),
    marginStart: GetScaledValue(80),
    gap: GetScaledValue(40),
  },
});
