import { FlatList, View } from 'react-native';
import React, { useCallback, useState } from 'react';
import Icon from '../../atoms/Icon';
import { TODLogoPNG } from '../../../assets/icons';
import {
  ApiRels,
  FONT_FAMILY,
  FONT_SIZES,
  ProfileTypes,
} from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import AvatarWithText from '../../molecules/AvatarWithText';
import EditIcon from '../../molecules/AvatarWithText/EditIcon';
import styles from './styles';
import Text from '../../atoms/Text';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { useProfile, profileActions } from '../../../context/Profile';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { GetScaledValue } from '@digiturk/screen-size';
import { setAuth } from '../../../middleware/services/api';
import { getLink, isAnimationsDisabled } from '../../../helpers/CommonHelper';
import { makeApiCall } from '../../../middleware/dynamic';
import { menuActions, useMenu } from '../../../context';

/**
 *
 * @param {object} props - props
 * @param {Array} props.data - data
 * @param {Function} props.navigate - navigate
 * @returns {module:JSX.Element} -
 */
const SwitchProfile = ({ data, navigate }) => {
  const { menuState, menuDispatch } = useMenu();
  const { profileState, profileDispatch } = useProfile();
  const [currentFocus, setCurrentFocus] = useState('');

  const onBecameFocused = useCallback((_, selected) => {
    setCurrentFocus(selected.focusKey);
  }, []);

  const switchProfile = useCallback(async (selected) => {
    const { url, method } = getLink(
      selected.item,
      ApiRels.PROFILE.SWITCH_PROFILE
    );

    const response = await makeApiCall({
      url,
      method,
      body: { pinCode: '' },
    });

    if (response) {
      setAuth(response.at);
      profileDispatch(profileActions.setSelectedProfile(selected.item));

      if (selected.item.profileType === ProfileTypes.KID) {
        const { url: kidMenu } = getLink(menuState.data, ApiRels.KIDS_MENU);
        const menus = await makeApiCall({ url: kidMenu });

        if (menus) {
          menuDispatch(menuActions.setMenu(menus));
          navigate(NavigationRoutes.kids, {}, 'kids-raillist-0');
        }
      } else {
        const { url: menu } = getLink(menuState.data, ApiRels.SELF);
        const menus = await makeApiCall({ url: menu });

        if (menus) {
          menuDispatch(menuActions.setMenu(menus));
          navigate(NavigationRoutes.home, {}, 'home-raillist-0');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEnterPress = useCallback((selected) => {
    const params = {
      isEdit: false,
      currentProfileId: selected.item.userProfileId,
    };

    const currentProfile =
      selected.item.userProfileId ===
      profileState.selectedProfile?.userProfileId;

    if (selected.item.isAddProfile) {
      navigate(NavigationRoutes.addprofile);

      return;
    }

    if (
      !currentProfile &&
      selected.item.profileType === ProfileTypes.RESTRICTED
    ) {
      navigate(NavigationRoutes.profilelogin, params, 'login-numpad-0');

      return;
    }

    switchProfile(selected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditPress = useCallback(
    ({ userProfileId, profileType }) => {
      if (profileState?.selectedProfile) {
        const params = {
          isEdit: true,
          currentProfileId: userProfileId,
        };

        if (
          profileType === ProfileTypes.RESTRICTED &&
          userProfileId !== profileState.selectedProfile?.userProfileId
        ) {
          navigate(NavigationRoutes.profilelogin, params, 'login-numpad-0');
        } else navigate(NavigationRoutes.manageprofile, params);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileState.profiles]
  );

  const Skeleteon = useCallback(() => {
    return (
      <View style={styles.skeletonContainer}>
        {new Array(5).fill(0).map((_, index) =>
          isAnimationsDisabled ? (
            <View
              key={index}
              style={{
                width: GetScaledValue(200),
                height: GetScaledValue(200),
                borderRadius: GetScaledValue(20),
                backgroundColor: colors.neutral700,
              }}
            />
          ) : (
            <ContentLoader
              key={index}
              speed={1}
              style={styles.skeleton}
              backgroundColor={colors.neutral700}
              foregroundColor={colors.neutral500}>
              <Rect
                x="0"
                y="0"
                rx={GetScaledValue(20)}
                ry={GetScaledValue(20)}
                width={GetScaledValue(200)}
                height={GetScaledValue(200)}
              />
            </ContentLoader>
          )
        )}
      </View>
    );
  }, []);

  const renderItem = useCallback(
    ({ item, index }) => {
      const opacity = currentFocus.includes(index) ? 1 : 0.5;
      const selected = profileState?.selectedProfile;

      const cond = selected?.profileType !== ProfileTypes.KID;

      return (
        <View style={[styles.itemContainer, { opacity }]}>
          <AvatarWithText
            item={item}
            index={index}
            focusKey={`profile-${index}`}
            downFocusKey={`edit-${index}`}
            onBecameFocused={onBecameFocused}
            onEnterPress={onEnterPress}
            rightFocusKey={
              index < data?.length - 1 ? `profile-${index + 1}` : false
            }
            leftFocusKey={index === 0 ? false : `profile-${index - 1}`}
          />
          {currentFocus.includes(index) && cond && (
            <EditIcon
              focusKey={`edit-${index}`}
              upFocusKey={`profile-${index}`}
              isAddProfile={item.isAddProfile}
              onEnterPress={() => onEditPress(item)}
            />
          )}
        </View>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFocus]
  );

  return (
    <View style={styles.container}>
      <Icon
        icon={TODLogoPNG}
        height={60}
        width={145}
        style={styles.iconContainer}
      />
      <Text
        text={'Who is watching ?'}
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.HEADING1}
        color={colors.neutral300}
      />
      <FlatList
        horizontal
        data={data}
        scrollEnabled={false}
        renderItem={renderItem}
        keyExtractor={(_, index) => index.toString()}
        contentContainerStyle={styles.contentContainerStyle}
        ListEmptyComponent={Skeleteon}
      />
    </View>
  );
};

export default SwitchProfile;
