import React, {
  memo,
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';
import { useMenu } from '../../../context';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { getLink, outOfScopes } from '../../../helpers/CommonHelper';
import useLazyLoadOnFlatlist from '../../../hooks/useLazyLoadOnFlatlist';
import Hero from '../../molecules/SelectedComponent/Hero';
import Skeleton from './skeleton';
import RailList from './railList';
import { PageContentPropTypes } from './proptypes';
import styles from './styles';
import { makeApiCall } from '../../../middleware/dynamic';
import { ApiRels, contentTypeList } from '../../../helpers/Enums';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import AnimatableListWrapper from '../AnimatableListWrapper';

/**
 * Page content component of pages
 *
 * @param {object} props - props
 * @param {string} props.urlSlug - urlSlug
 * @param {string} props.prefix - prefix
 * @param {string|boolean} props.leftFocusKey - leftFocusKey
 * @param {string} props.focusKey - focusKey
 * @param {object} props.viewAllUrl - url for viewall
 * @param {object} props.navigation - navigation
 * @returns {module:JSX.Element} - JSX.Element
 */
const PageContentWrapper = ({
  urlSlug,
  prefix,
  leftFocusKey,
  focusKey,
  viewAllUrl,
  navigation,
}) => {
  const railListRef = useRef();
  const { menuState } = useMenu();

  const memoizedMenuState = useMemo(() => menuState, [menuState]);
  const [isLoading, setIsLoading] = useState(true);
  const [globalFocusedItem, setGlobalFocusedItem] = useState({});
  const [baseRailList, setBaseRailList] = useState([]);
  const { dynamicRailList, railFocusIndexSetter, onEndReached } =
    useLazyLoadOnFlatlist(baseRailList);

  /**
   * Fetch Page Rail List
   */
  useEffect(() => {
    let timeout;
    // eslint-disable-next-line require-jsdoc
    const fetchData = async (url, isViewAll = false) => {
      if (url) {
        const railList = await makeApiCall({ url });

        if (railList) {
          const rails = railList.rails
            .filter((rail) => outOfScopes(rail.railType))
            .map((rail) => ({
              ...rail,
              railType: rail.railType + (isViewAll ? 'ViewAll' : ''),
            }));

          setBaseRailList(rails);

          // loading finish
          timeout = setTimeout(() => {
            setIsLoading(false);
            setFocusCustom(focusKey);
          }, 1000);
        }
      }
    };

    if (urlSlug && memoizedMenuState.data?.items) {
      const { url } = getLink(
        memoizedMenuState.data?.items,
        ApiRels.SELF,
        'urlSlug',
        urlSlug
      );

      fetchData(url);
    } else {
      fetchData(viewAllUrl, true);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSlug, viewAllUrl, focusKey, memoizedMenuState?.data?.items]);

  /**
   * Rail Item OnEnterPress
   *
   * @param {object} selected - selected item
   */
  const onEnterPress = useCallback(
    (selected) => {
      // is livetv?
      // is the rail item is ViewAll?
      if (prefix === 'livetv-raillist') {
        navigation.navigate(NavigationRoutes.live);
      } else if (selected?.viewAll) {
        // NOTE: FlatList cannot change numColumns value at runtime. so it was replaced with navigation.replace method
        navigation.replaceNavigate(NavigationRoutes.viewAll, {
          url: selected?.url,
        });
      } else {
        // TODO: check useCallback usage in here
        const { url } = getLink(selected.item, ApiRels.SELF);

        navigation.navigate(NavigationRoutes.contentdetail, {
          url: url,
          contentType: contentTypeList[selected?.item?.contentType] || 1,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <View style={styles.contentArea}>
      {isLoading && <Skeleton />}
      <Hero globalFocusedItem={globalFocusedItem} />
      <AnimatableListWrapper
        style={styles.railContainer}
        globalFocusedItem={globalFocusedItem}>
        <RailList
          prefix={prefix}
          content={dynamicRailList}
          railListRef={railListRef}
          leftFocusKey={leftFocusKey}
          onEnterPress={onEnterPress}
          onEndReached={onEndReached}
          railFocusIndexSetter={railFocusIndexSetter}
          setGlobalFocusedItem={setGlobalFocusedItem}
          onScrollToIndexFailedHandler={() => {}}
          baseRailListLength={baseRailList.length}
        />
      </AnimatableListWrapper>
    </View>
  );
};

PageContentWrapper.propTypes = PageContentPropTypes;
PageContentWrapper.whyDidYouRender = false;

export default withFocusable()(memo(PageContentWrapper));
