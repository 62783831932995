import React, { useState } from 'react';

import { View } from 'react-native';
import styles from './styles';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import {
  BasicButton,
  SelectedButton,
  buttonThemes,
  buttonSize,
} from '../../molecules/buttons';

import useApiMode from '../../../models/apiMode';

import { reloadApp } from '../../../helpers/CommonHelper';
import { apiModes } from '../../../helpers/Constants';

/**
 * Developer Mode Page
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const DevMode = () => {
  const [mod, setMod] = useState('');
  const { changeAndStartThisMode } = useApiMode();

  /**
   * onEnterPressClear button
   */
  const onEnterPressClear = async () => {
    setMod(apiModes.test);
    await changeAndStartThisMode(apiModes.test);
  };

  return (
    <View style={styles.mainWrapper}>
      <Text
        text="Developer Mode Page"
        size={FONT_SIZES.HEADING1}
        fontFamily={FONT_FAMILY.SEMIBOLD}
      />
      <View style={styles.container}>
        <View style={styles.contentWrapper}>
          <SelectedButton
            text="Live"
            focusKey={'live'}
            downFocusKey={'test'}
            rightFocusKey={'clear'}
            size={buttonSize.large}
            style={styles.button}
            isSelected={mod === apiModes.live}
          />
          <SelectedButton
            text="Test"
            focusKey={'test'}
            upFocusKey={'live'}
            downFocusKey={'regression'}
            rightFocusKey={'save'}
            size={buttonSize.large}
            style={styles.button}
            isSelected={mod === apiModes.test}
            onEnterPress={() => setMod(apiModes.test)}
          />
          <SelectedButton
            text="Regression"
            focusKey={'regression'}
            upFocusKey={'test'}
            rightFocusKey={'restart'}
            size={buttonSize.large}
            style={styles.button}
            isSelected={mod === apiModes.regression}
            onEnterPress={() => setMod(apiModes.regression)}
          />
        </View>
        <View style={styles.contentWrapper}>
          <BasicButton
            text="Clear"
            theme={buttonThemes.transparent16}
            themeFocused={buttonThemes.primary600}
            focusKey={'clear'}
            leftFocusKey={'live'}
            downFocusKey={'save'}
            size={buttonSize.large}
            style={styles.button}
            onEnterPress={onEnterPressClear}
          />
          <BasicButton
            text="Save"
            theme={buttonThemes.transparent16}
            themeFocused={buttonThemes.primary600}
            focusKey={'save'}
            leftFocusKey={'test'}
            upFocusKey={'clear'}
            downFocusKey={'restart'}
            size={buttonSize.large}
            style={styles.button}
            onEnterPress={() => changeAndStartThisMode(mod)}
          />
          <BasicButton
            text="Restart"
            theme={buttonThemes.transparent16}
            themeFocused={buttonThemes.primary600}
            focusKey={'restart'}
            leftFocusKey={'regression'}
            upFocusKey={'save'}
            size={buttonSize.large}
            style={styles.button}
            onEnterPress={() => reloadApp()}
          />
        </View>
      </View>
    </View>
  );
};

export default DevMode;
