/**
 * With Zero
 *
 * @param {number} data - data
 * @returns {string} - data with zero
 */
export const withZero = (data) => {
  return String(data < 10 ? '0' + data : data);
};

/**
 * Time Keeper
 *
 * @param {object} date - data
 * @param {number} secondOffset - second offset
 * @param {object} dateDiff - data
 * @returns {object} - date object
 */
const timeKeeper = (date, secondOffset = 0, dateDiff) => {
  const nowDate = date ? new Date(date) : new Date();
  const secondDate = dateDiff ? new Date(dateDiff) : new Date();

  if (secondOffset && secondOffset !== 0) {
    nowDate.setSeconds(nowDate.getSeconds() + secondOffset);
  }

  return {
    day: withZero(nowDate.getDate()),
    dayText: nowDate.toLocaleString('default', { weekday: 'long' }),
    month: withZero(nowDate.getMonth() + 1),
    monthText: nowDate.toLocaleString('default', { month: 'long' }),
    monthShortText: nowDate.toLocaleString('default', { month: 'short' }),
    dateFullText:
      withZero(nowDate.getDate()) +
      ' ' +
      nowDate.toLocaleString('default', { month: 'long' }) +
      ' ' +
      nowDate.getFullYear().toString(),
    dateLongText:
      withZero(nowDate.getDate()) +
      '.' +
      withZero(nowDate.getMonth() + 1) +
      '.' +
      nowDate.getFullYear().toString() +
      ' ' +
      nowDate.toLocaleTimeString('default'),
    timeText: nowDate.toLocaleTimeString('default'),
    year: nowDate.getFullYear().toString(),
    hour: withZero(nowDate.getHours()),
    minute: withZero(nowDate.getMinutes()),
    hourAndMin:
      withZero(nowDate.getHours()) + ':' + withZero(nowDate.getMinutes()),
    hourAndMinWithSecond:
      withZero(nowDate.getHours()) +
      ':' +
      withZero(nowDate.getMinutes()) +
      ':' +
      withZero(nowDate.getSeconds()),
    mkTime: nowDate.getTime().toString(),
    diffTime: (nowDate - secondDate) / 1000,
    fullDate:
      withZero(nowDate.getDate()) +
      '/' +
      withZero(nowDate.getMonth() + 1) +
      '/' +
      nowDate.getFullYear().toString(),
  };
};

export default timeKeeper;
