import { View } from 'react-native';
import React, { useCallback, useMemo } from 'react';
import Language from './language';
import { FONT_FAMILY, FONT_SIZES, SectionTypes } from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import { translate } from '@digiturk/i18n';
import ButtonsGroup from '../../molecules/ButtonsGroup/ButtonsGroup';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import { useFocusEffect } from '@react-navigation/native';
import Text from '../../atoms/Text';
import styles from './styles';
import { TrashStroke } from '../../../assets/icons';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { buttonSize } from '../../molecules/buttons';

/**
 *
 * @param {object} props -
 * @param {boolean} props.isEdit -
 * @param {object} props.currentProfile -
 * @param {Function} props.onSelectHandler -
 * @param {object} props.navigation - navigation
 * @returns {module:JSX.Element}-
 */
const Main = ({ isEdit, currentProfile, onSelectHandler, navigation }) => {
  const handleSelect = useCallback((type, updatedObject) => {
    onSelectHandler(type, updatedObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createButtons = useCallback(
    (mode) => {
      const baseButtons = [
        {
          text: translate(Language.EDIT_PROFILE_NAME),
          onEnterPress: () => handleSelect(SectionTypes.Name),
          isAvailable: true,
        },
        {
          text: translate(Language.EDIT_PROFILE_AVATAR),
          onEnterPress: () => handleSelect(SectionTypes.Avatar),
          isAvailable: true,
        },
        {
          text: translate(Language.EDIT_PROFILE_PROFILE_TYPES),
          onEnterPress: () => handleSelect(SectionTypes.ProfileType),
          isAvailable: true,
        },
        {
          text: translate(Language.EDIT_PROFILE_NO_SPOILER),
          textTwo: currentProfile?.hideScores
            ? translate(Language.ON)
            : translate(Language.OFF),
          onEnterPress: () =>
            handleSelect(null, { hideScores: !currentProfile?.hideScores }),
          isAvailable: true,
          downFocusKey: 'add-new-profile-button',
        },
      ];

      if (mode === 'edit') {
        const additionalButton = [
          {
            text: translate(Language.EDIT_PROFILE_DELETE_PROFILE),
            icon: TrashStroke,
            onEnterPress: () =>
              navigation.navigate(NavigationRoutes.delete, {
                currentProfileId: currentProfile?.userProfileId,
              }),
            isAvailable: !currentProfile?.isMainProfile,
          },
        ];

        baseButtons.push(...additionalButton);
      }

      return baseButtons;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProfile]
  );

  const buttons = useMemo(
    () =>
      createButtons(isEdit ? 'edit' : 'add').filter(
        (button) => button.isAvailable
      ),
    [createButtons, isEdit]
  );

  useFocusEffect(
    useCallback(() => {
      setFocusCustom(isEdit ? 'edit-button-0' : 'add-button-0');
    }, [isEdit])
  );

  return (
    <View>
      <Text
        text={
          isEdit
            ? translate(Language.EDIT_PROFILE)
            : translate(Language.ADD_NEW_PROFILE)
        }
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.DISPLAYSMALL}
        color={colors.shades00}
      />
      <Text
        text={translate(Language.EDIT_PROFILE_DESCRIPTION)}
        fontFamily={FONT_FAMILY.REGULAR}
        size={FONT_SIZES.HEADING3}
        color={colors.neutral200}
        style={styles.description}
      />
      <ButtonsGroup
        buttons={buttons}
        size={buttonSize.medium}
        prefix={isEdit ? 'edit-button' : 'add-button'}
      />
    </View>
  );
};

export default Main;
