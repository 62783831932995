/* eslint-disable require-jsdoc */
/* eslint-disable no-use-before-define */
import React, { useCallback, useRef } from 'react';
import { Player, PlayerEvent } from 'bitmovin-player';
import { PlayerPropTypes, PlayerDefaultProps } from './proptypes';
import {
  playreadyLicenceURL,
  videwineLicenceURL,
} from '../../../helpers/Constants';
import { AsidController, AsidIntegrationLogger } from '../../../libs/fmts/asid';
import { useFocusEffect } from '@react-navigation/native';
/**
 * Player component
 *
 * @param {object} props -props
 * @param {Function} props.onLoad - on load callback
 * @param {Function} props.handleSubtitleText - on    handleSubtitleText,
 * @param {object} props.onCurrentTimeUpdate - on current time update callback - currentTime
 * @param {object} props.onBufferHandler - onBufferHandler
 * @param {object} props.source - player source
 * @param {number} props.id - player unic id
 * @returns {module:JSX.Element} - player html
 */
const VodPlayer = ({
  id,
  onLoad,
  onCurrentTimeUpdate,
  onBufferHandler,
  source,
  handleSubtitleText,
}) => {
  const playerConfig = {
    key: '047DDDE8-7D3F-4355-959A-4DC51EC5B10E',
    ui: false,
  };
  const timeout = useRef();

  console.info('  >>>>>>>>> source vod', source);

  const playerSource = {
    dash: source?.dash,
    hls: source?.hls,
    progressive:
      'https://bitdash-a.akamaihd.net/content/MI201109210084_1/MI201109210084_mpeg-4_hd_high_1080p25_10mbits.mp4',
    poster:
      'https://bitdash-a.akamaihd.net/content/MI201109210084_1/poster.jpg',
    smooth: `${source?.dash}`,
    drm: {
      // widevine licence test for chrome browser or chromium base browser
      widevine: {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/base64',
          Authorization: 'Bearer ' + encodeURIComponent(source?.ticket),
        },
        LA_URL: `${videwineLicenceURL}${source?.mediaId}`,
        prepareMessage: (keyMessage) => {
          console.info('Widevine drm message: ' + JSON.stringify(keyMessage));

          return keyMessage.message;
        },
      },
      // playready licence test for microsoft edge browser
      playready: {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/base64',
          Authorization: 'Bearer ' + encodeURIComponent(source?.ticket),
        },
        LA_URL: `${playreadyLicenceURL}${source?.mediaId}&ls_session=${source?.ticket}`,
        prepareMessage: (keyMessage) => {
          console.info('Playready drm message: ' + JSON.stringify(keyMessage));

          return keyMessage.message;
        },
      },
    },
  };

  /**
   * İnitialize Player
   */
  const initializePlayer = useCallback(() => {
    const player = new Player(document.getElementById(id), playerConfig);
    var playerCheck = null;

    player.load(playerSource).then(() => {
      player.on(PlayerEvent.TimeChanged, (event) => {
        onCurrentTimeUpdate && onCurrentTimeUpdate(event);
        onBufferHandler && onBufferHandler(player.getVideoBufferLength());
      });

      player.on(PlayerEvent.SourceUnloaded, (error) => {
        console.info('Source Error', error);
      });

      player.on(PlayerEvent.Destroy, (error) => {
        console.info('Destroyed Vod', error);
      });

      player.on(PlayerEvent.Ready, (event) => {
        onLoad && onLoad(player);
      });

      /**
       *  FMTS Source config
       *
       *  @param {string} e - e
       *  @returns {object} - config
       */
      const sourceConfig = (e) => {
        console.info(e);
        if (typeof source?.fmts !== 'undefined') {
          let _fmts = JSON.parse(source?.fmts);

          _fmts?.su && _fmts?.su.slice(-1) === '/' && _fmts?.su.slice(0, -1);
          console.info('>>>>>> fmts filter', _fmts);

          return Promise.resolve(
            JSON.stringify(_fmts)
            // JSON.stringify({
            //   md: 'symmetric',
            //   su: 'https://tod.asidintegration.fmtsapi.com',
            //   at: '04f18697915b072f66ae3cbf71b10058xr9IwCGEasO%2Fc5UsT%2B6HHKgcLn4ZyyfcRBNynWIcOr2J8KkoVNnFB7mpE%2Ba2ZZ%2By1oGp2HSvlwPHWDsG14e366sjr9Ev1khpx7gBhhGOQ1kmkEKm2DSuyuXSnRlcDS%2FjZAb6VxWSK1i2ZifPKMUTpQ%3D%3D',
            // })

            // JSON.stringify({
            //   at: source?.fmts,
            //   su: 'https://asidintegration.fmtsapi.com',
            //   md: 'symmetric',
            //   cd: null,
            // })
          );
        } else return {};
      };

      /**
       * TamperCallback logger
       *
       * @param {string} issueType - log issue type
       */
      const logger = (issueType) => {
        console.info('Escalating issue type ', issueType);
      };

      AsidIntegrationLogger.setLogCallback(console.info, 'DCzmXa6VqqFw48Wu');

      /**
       * Terminate Callback
       *
       * @param {Function} _playerCheck - watermark Asid controller
       */
      function terminateCallback(_playerCheck) {
        console.info('Termination requested: disabling player');
        _playerCheck.terminateExistingSession();
      }

      player.on(PlayerEvent.Ready, async function (event) {
        if (playerCheck === null) {
          (playerCheck = new AsidController()).setTamperCallback(logger),
            playerCheck.setTerminateCallback(terminateCallback),
            playerCheck.setDecryptionHandler(sourceConfig),
            Math.floor(1e3 * player.getCurrentTime());
          try {
            await playerCheck.startWithDomVideo(
              {
                initToken: source?.fmts,
                validationKey: '<Your-Key>',
                failureRecallFrequency: '',
                appName: 'Bitmovin Example',
                appVersion: '2.0.0',
                appIdentifier: 'dev',
                assetTitle: 'More Cowbell',
                naturalVideoWidth: 800,
                naturalVideoHeight: 600,
              },
              'bitmovinplayer-video-player-for-video-screen'
            );
          } catch (e) {
            console.error(e);
          }
        }

        timeout.current = setTimeout(() => {
          player.play();
        }, 1500);
      });

      player.on(PlayerEvent.CueEnter, (event) => {
        handleSubtitleText(event.text);
      });

      player.on(PlayerEvent.CueExit, (event) => {
        handleSubtitleText('');
      });
    });

    return () => {
      player?.destroy();
    };
  }, [playerConfig, playerSource]);

  useFocusEffect(initializePlayer);

  return <div id={id} />;
};

VodPlayer.propTypes = PlayerPropTypes;
VodPlayer.defaultProps = PlayerDefaultProps;
VodPlayer.whyDidYouRender = true;

export default VodPlayer;
