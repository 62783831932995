import { View, FlatList } from 'react-native';
import React, { useCallback, useRef, useState } from 'react';
import { BasicButton } from '../../molecules/buttons';
import { buttonThemes } from '../buttons';
import { MoreStroke } from '../../../assets/icons';
import LiveChannelItem from '../LiveChannelItem';
import style from './styles';
import { buttonSize } from '../LiveChannelItem/configs';
import { isAnimationsDisabled } from '../../../helpers/CommonHelper';

/**
 * LiveChannelLists
 *
 * @param {object} props - props
 * @param {string} props.downFocusKey - downFocusKey
 * @param {Array} props.channelList - channelList
 * @returns {module:JSX.Element} - JSX.Element
 */
const LiveChannelLists = ({ downFocusKey, channelList }) => {
  const ref = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const _downFocusKey =
    isExpanded && channelList.length > 0
      ? 'channel-list-0'
      : 'hidden-focus-placeholder';

  const onBecameFocused = useCallback((_, selected) => {
    ref.current.scrollToIndex({
      index: selected.index,
      animated: !isAnimationsDisabled,
    });
  }, []);

  const renderItem = useCallback(({ item, index }) => {
    return (
      <LiveChannelItem
        index={index}
        channel={item}
        theme={buttonThemes.transparent16}
        themeFocused={buttonThemes.primary}
        onBecameFocused={onBecameFocused}
        focusKey={`channel-list-${index}`}
        downFocusKey={
          index === channelList.length - 1 ? false : `channel-list-${index + 1}`
        }
        upFocusKey={`channel-list-${index - 1}`}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      <BasicButton
        size={buttonSize.big}
        theme={buttonThemes.neutralQuaternary}
        themeFocused={buttonThemes.blackWhite}
        icon={MoreStroke}
        onEnterPress={() => setIsExpanded(!isExpanded)}
        focusKey="channel-list-expand-button"
        downFocusKey={isExpanded ? _downFocusKey : downFocusKey}
        leftFocusKey="4kButton"
        text="Channels"
      />

      {isExpanded && (
        <View style={style.expandedContainer}>
          <FlatList
            ref={ref}
            data={channelList}
            keyExtractor={(_, index) => index.toString()}
            renderItem={renderItem}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={style.listItemContainer}
          />
        </View>
      )}
    </View>
  );
};

export default LiveChannelLists;
