import React, { useCallback, useRef, useState, useEffect } from 'react';
import { View, FlatList } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';

import style from './styles';
import { isAnimationsDisabled, isWeb } from '../../../helpers/CommonHelper';
import FocusableSection from './section';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import { makeApiCall } from '../../../middleware/dynamic';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';

/**
 * A React component for displaying more episodes.
 *
 * @param {object} props - The props object containing the children.
 * @param {string} props.contentPageUrl - The props object containing the children.
 * @returns {React.ReactElement} A View component containing the provided children.
 */
const MoreEpisodes = ({ contentPageUrl }) => {
  const url = contentPageUrl;

  const [data, setData] = useState(null);
  const [, setImage] = useState('');

  const railListRef = useRef();

  const [, setIsLoading] = useState(true);

  const [, setIndex] = useState(0);

  const styles = style();
  let offsetArr = [GetScaledValue(0), GetScaledValue(1080)];

  const navigation = useCustomNavigation();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  useEffect(() => {
    (async () => {
      if (url) {
        const response = await makeApiCall({ url });

        const newData = response.rails.filter(
          (item) =>
            item.railType !== 'ContentFilmDetail' &&
            item.railType !== 'ContentSeriesDetail'
        );

        setData(newData);

        setTimeout(() => setIsLoading(false), 1000);
        setTimeout(() => {
          if (newData[0].railType === 'MoreLikeThis') {
            setFocusCustom('detail-0-NewOnTod-0');
          } else if (newData[0].railType === 'ContentSeriesSeasonsDetail') {
            setFocusCustom('detail-0-season-0');
          }
        }, 100);
      }
    })();
  }, [url]);

  const counter = useCallback(
    (_data, idx) => {
      if (idx !== 0) {
        offsetArr[idx + 1] = offsetArr[idx] + GetScaledValue(_data);
      }

      return offsetArr[idx];
    },
    [offsetArr]
  );

  const onScrollToIndexFailedHandler = useCallback(
    ({ index: idx, averageItemLength }) => {
      railListRef?.current?.scrollToOffset({
        offset: idx * averageItemLength,
        animated: !isAnimationsDisabled,
      });

      setTimeout(() => {
        railListRef?.current?.scrollToIndex({
          index: idx,
          animated: !isAnimationsDisabled,
        });
      }, 100);
    },
    []
  );

  const onBecameFocus = useCallback(
    (coordinate, selected) => {
      setTimeout(() => {
        if (isWeb) {
          railListRef.current?.scrollToOffset({
            offset: counter(coordinate.height, selected?.index),
            animated: !isAnimationsDisabled,
          });
        } else {
          railListRef.current?.scrollToIndex({
            index: selected?.index,
            animated: !isAnimationsDisabled,
          });
        }
      }, 100);

      setIndex(selected?.index);
    },
    [counter]
  );

  /**
   * Render item
   *
   * TODO: Check this render method for is same with detail page
   */
  const renderItem = useCallback(
    ({ item, index: idx }) => (
      <FocusableSection
        item={item}
        index={idx}
        setImage={setImage}
        focusKeyPrefix={'detail'}
        contentLength={data.length}
        onBecameFocused={onBecameFocus}
        navigation={navigation}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const keyExtractor = useCallback((_item, _index) => `detail_${_index}`, []);

  return (
    <View style={styles.fullScreenContainer}>
      <FlatList
        ref={railListRef}
        data={data}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        onScrollToIndexFailed={onScrollToIndexFailedHandler}
        removeClippedSubviews={false}
        scrollEnabled={false}
        ListFooterComponent={<View style={styles.fullScreenContainer} />}
      />
    </View>
  );
};

export default MoreEpisodes;
