/* eslint-disable require-jsdoc */
import { useRef } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import {
  lastFocusKey,
  getCurrentFocusKey,
  setFocusCustom,
} from '../helpers/FocusHelper';
import { getId, resetId } from '../helpers/CommonHelper';
import NavigationRoutes from '../navigation/NavigationRoutes';

/**
 * Sidebar Flow
 * page list to apply sidebar flow
 */
const sidebarFlowPageList = [
  NavigationRoutes.home,
  NavigationRoutes.sports,
  NavigationRoutes.tvshows,
  NavigationRoutes.movies,
  NavigationRoutes.mylist,
  NavigationRoutes.search,
  NavigationRoutes.viewAll,
];

/**
 * Configuration
 */
const config = {
  isVisibleLogs: false,
};

// Use Custom Navigation
const useCustomNavigation = () => {
  const navigation = useNavigation();

  const lastFocusKeyInPage = useRef();

  /**
   * Log writer
   */
  const stateLogger = () => {
    if (navigation.getState().routes?.length) {
      console.info(`* NAVIGATION STATE`);
      navigation.getState().routes.map((item, index) => {
        console.info(index, ' - ', JSON.stringify(item));
      });
    } else {
      console.info('* NAVIGATION STATE is NULL');
    }
  };

  const countPageOnStack = navigation.getState().routes.length;

  // Get Current Page
  const currentPage = () => {
    return navigation.getState().routes[
      navigation.getState().routes?.length - 1
    ];
  };

  // Get Count of Detail Page
  const countDetailPages = () => {
    return navigation.getState().routes.filter((itemRoute, index) => {
      if (itemRoute.name === NavigationRoutes.contentdetail) {
        itemRoute.routeIndex = index;

        return itemRoute;
      }
    });
  };

  // Sidebar Flow
  const sidebarGoBackFlow = () => {
    const currentFocusKey = getCurrentFocusKey();

    if (currentFocusKey?.indexOf('sidebar') === -1) {
      const currentId = getId(currentFocusKey);

      if (currentId != null) {
        if (parseInt(currentId, 10) > 0) {
          lastFocusKeyInPage.current = resetId(currentFocusKey);
          setFocusCustom(lastFocusKeyInPage.current);
        } else {
          setFocusCustom('sidebar');
        }
      } else {
        setFocusCustom('sidebar');
      }
    } else {
      navigate(NavigationRoutes.exit);
    }
  };

  // Detail Page Flow
  const detailPageFlow = (route, params) => {
    if (countDetailPages().length >= 2) {
      let newRoutes = JSON.parse(JSON.stringify(navigation.getState().routes));

      // remove first detail page
      newRoutes.splice(countDetailPages()[0].routeIndex, 1);

      // add new detail page
      newRoutes.push({ name: route, params });

      // dispatch all route changes to navigation state
      navigation.dispatch({
        ...CommonActions.reset({
          index: newRoutes.length - 1,
          routes: newRoutes,
        }),
      });
    } else {
      navigation.push(route, params);
    }
  };

  /**
   * Navigate with reset navigation state
   *
   * @param {string} route - route name
   * @param {object} params - params object
   */
  const navigateWithResetState = (route, params) => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: route,
            params: params,
          },
        ],
      })
    );
  };

  // navigate
  const navigate = (route, params, nextFocus) => {
    lastFocusKey.set({ key: currentPage().key, data: getCurrentFocusKey() });

    switch (true) {
      // Sidebar Pages Rules
      case sidebarFlowPageList.includes(route):
        // in cases, full of stack after going to main / summary pages
        if (countPageOnStack > 1) {
          navigateWithResetState(route, params);
        } else {
          navigation.replace(route, params);
        }

        break;

      // Detail Page Rules
      case route === NavigationRoutes.contentdetail:
        detailPageFlow(route, params);
        // navigation.navigate(route, params);
        break;

      // Default
      default:
        navigation.navigate(route, params);
        break;
    }

    nextFocus && setFocusCustom(nextFocus);

    if (config.isVisibleLogs) stateLogger();
  };

  // goBack
  const goBack = () => {
    switch (true) {
      // Sidebar Pages Rules
      case sidebarFlowPageList.includes(currentPage().name):
        sidebarGoBackFlow();
        break;

      // Default case
      default:
        const goBeckPagelastFocusKey = lastFocusKey.get({
          key: navigation.getState().routes[
            navigation.getState().routes?.length - 2
          ]?.key,
        });

        setTimeout(() => {
          setFocusCustom(goBeckPagelastFocusKey);
        }, 500);

        navigation.goBack();

        if (config.isVisibleLogs) stateLogger();

        break;
    }
  };

  // replace
  const replaceNavigate = (route, params) => {
    lastFocusKey.set({ key: currentPage().key, data: getCurrentFocusKey() });
    navigation.replace(route, params);

    if (config.isVisibleLogs) stateLogger();
  };

  return {
    navigate,
    goBack,
    replaceNavigate,
    addListener: navigation.addListener,
  };
};

export default useCustomNavigation;
