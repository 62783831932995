import { lazy } from 'react';

const Commentary = lazy(() => import('../../organisms/Commantary'));
const TeamStats = lazy(() => import('../../organisms/TeamStats'));
const Highlights = lazy(() => import('../../organisms/Highlights'));
const TopPlayers = lazy(() => import('../../molecules/TopPlayers'));
const Lineups = lazy(() => import('../../organisms/Teams'));

const sectionComponentsMap = {
  default: Commentary,
  Commentary: Commentary,
  TeamStats: TeamStats,
  Highlights: Highlights,
  TopPlayers: TopPlayers,
  Lineups: Lineups,
};

export default sectionComponentsMap;
