import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ProfileWrapper from '../../templates/ProfileWrapper';
import styles from './styles';
import { View } from 'react-native';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import SelectedChildren from './SelectedChildren';
import { useRoute } from '@react-navigation/native';
import { ApiRels, SectionTypes } from '../../../helpers/Enums';
import { profileActions, useProfile } from '../../../context';
import { getLink } from '../../../helpers/CommonHelper';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * EDIT New Profile
 *
 * @returns {module:JSX.Element} -
 */
const ManageProfile = () => {
  const { profileState, profileDispatch } = useProfile();
  const navigation = useCustomNavigation();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  const { currentProfileId, isEdit } = useRoute().params;

  const [selectedComponent, setSelectedComponent] = useState(
    SectionTypes.Default
  );

  const Children = useMemo(
    () => SelectedChildren[selectedComponent],
    [selectedComponent]
  );

  const currentProfile = useMemo(() => {
    return profileState?.profiles.find(
      (p) => p.userProfileId === currentProfileId
    );
  }, [profileState, currentProfileId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSelectedComponent(SectionTypes.Main);
    }, 10);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onSelectHandler = useCallback(
    async (_selectedComponent, updatedObject) => {
      if (updatedObject) {
        const { url, method } = getLink(
          currentProfile,
          ApiRels.PROFILE.UPDATE_PROFILE
        );

        const body = { ...currentProfile, ...updatedObject };

        const response = await makeApiCall({ url, method, body });

        if (response) {
          profileDispatch(profileActions.updateProfile(body));
        }
      }

      if (_selectedComponent) {
        setSelectedComponent(_selectedComponent);
      }
    },
    [currentProfile, profileDispatch]
  );

  return (
    <View style={styles.container}>
      <ProfileWrapper profile={currentProfile}>
        <Children
          isEdit={isEdit}
          prefix={isEdit ? 'edit' : 'add'}
          onSelectHandler={onSelectHandler}
          currentProfile={currentProfile}
          navigation={navigation}
        />
      </ProfileWrapper>
    </View>
  );
};

export default ManageProfile;
