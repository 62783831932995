import React, { memo, useEffect, useState } from 'react';
import { View } from 'react-native';
import { getMenus } from '../../../middleware/menus';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import Icon from '../../atoms/Icon';
import Loader from '../../../assets/icons/loader.gif';
import { setAuth } from '../../../middleware/services/api';
import styles from './styles';
import { menuActions, useMenu } from '../../../context/Menu';

import { getLink } from '../../../helpers/CommonHelper';
import { ApiRels } from '../../../helpers/Enums';

import { useAuth } from '../../../context/Auth';
import { profileActions, useProfile } from '../../../context';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { setRefreshTokenUrl } from '../../../libs/refreshAccessToken';

/**
 * Splash screen
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const Splash = () => {
  const { menuState, menuDispatch } = useMenu();
  const { authState } = useAuth();
  const { profileDispatch } = useProfile();
  const { addListener, replaceNavigate } = useCustomNavigation();

  const [isError, setIsError] = useState(false);

  // page change listener for stop .gif animations
  useEffect(() => {
    const pageBlurEvent = addListener('blur', () => {
      setIsError(true);
    });

    return pageBlurEvent;
  }, [addListener]);

  useEffect(() => {
    if (menuState.isInitiated) {
      checkAuth();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, menuState]);

  /**
   * Checks if the user is authenticated by retrieving the auth item from AsyncStorage.
   *
   * @returns {void}
   */
  const checkAuth = async () => {
    if (authState.at) {
      setAuth(authState.at);

      const { url: nextLink } = getLink(
        menuState?.data,
        ApiRels.PROFILE.GET_PROFILES
      );

      replaceNavigate(NavigationRoutes.profiles, { url: nextLink });
    } else {
      profileDispatch(profileActions.setSelectedProfile(null));
      replaceNavigate(NavigationRoutes.landing);
    }
  };

  useEffect(() => {
    fetchMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetches menus and updates the menu response state.
   */
  const fetchMenus = async () => {
    const menus = await getMenus();

    const { url: refreshUrl } = getLink(menus, ApiRels.MENUS.REFRESH);

    setRefreshTokenUrl(refreshUrl);

    menuDispatch(menuActions.setMenu(menus));
  };

  return (
    <View style={styles.pageArea}>
      <View style={styles.logo}>
        {!isError && <Icon icon={Loader} width={260} height={260} />}
      </View>
    </View>
  );
};

Splash.whyDidYouRender = false;

export default memo(Splash);
