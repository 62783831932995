import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import { ApiRels, FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { getLink } from '../../../helpers/CommonHelper';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 * AccountDetails Settings Component
 *
 * @param {object} props - props
 * @param {object} props.account - account
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const AccountDetails = ({ account }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    const fetchData = async () => {
      const { url } = getLink(account, ApiRels.SELF);
      const response = await makeApiCall({ url });

      response && setUser(response);
    };

    fetchData();
  }, [account]);

  return (
    <View style={styles.container}>
      <Text
        text={account?.title}
        size={FONT_SIZES.HEADING3}
        fontFamily={FONT_FAMILY.SEMIBOLD}
      />
      <View style={styles.name}>
        <Text
          text={'Username'}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          style={styles.text}
        />
        <Text
          text={user?.username}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral300}
        />
      </View>
      <View style={styles.contact}>
        <Text
          text={'E-mail'}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          style={styles.text}
        />
        <Text
          text={user?.email}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral300}
        />
      </View>
    </View>
  );
};

export default AccountDetails;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral800,
    padding: GetScaledValue(40),
    borderRadius: GetScaledValue(20),
  },
  name: {
    flexDirection: 'row',
    marginTop: GetScaledValue(30),
  },
  contact: {
    flexDirection: 'row',
    marginTop: GetScaledValue(20),
  },
  text: {
    minWidth: GetScaledValue(160),
  },
});
