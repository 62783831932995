import { appActions, useApp } from '../context';
import { apiModes } from '../helpers/Constants';

/**
 * Development Mode
 *
 * @returns {object} - return object
 */
const useApiMode = () => {
  const { appDispatch } = useApp();

  /**
   * Set Mode to Regression
   */
  const setModeToReg = async () => {
    await appDispatch(appActions.setApiModeState(apiModes.regression));
  };

  /**
   * Set Mode to Test
   */
  const setModeToTest = async () => {
    await appDispatch(appActions.setApiModeState(apiModes.test));
  };

  /**
   * Change Mode when Restart App
   *
   * @param {string} mode - search text
   */
  const changeAndStartThisMode = (mode) => {
    if (mode) {
      switch (mode) {
        case apiModes.test:
          setModeToTest();
          break;
        case apiModes.regression:
          setModeToReg();
          break;
      }
    }
  };

  /**
   * TODO: Dev Mode Page
   * Change Mode with User and Password in Search Screen,
   *
   * @param {string} _user - user
   * @param {string} _pass - password
   */
  const changeModeWithUserAndPass = (_user, _pass) => {
    console.info('* log changeModeWithUserAndPass');
  };

  return {
    changeModeWithUserAndPass,
    changeAndStartThisMode,
  };
};

export default useApiMode;
