import { useState, useCallback, useEffect, useMemo } from 'react';
import { uniqueByField } from '../helpers/CommonHelper';

const config = {
  eachTriggerPoint: 2,
};

/**
 * Lazy Load On FlatList Custon Hook
 *
 * @param {Array} baseRailList - baseRailList
 * @returns {object} - object
 */
const useLazyLoadOnFlatlist = (baseRailList) => {
  const [focusIndex, setFocusIndex] = useState(0);
  const [dynamicRailList, setDynamicRailList] = useState([]);

  const baseListWithId = useMemo(() => {
    return baseRailList.map((item, index) => ({
      ...item,
      id: index,
    }));
  }, [baseRailList]);

  const railFocusIndexSetter = useCallback((data) => {
    setFocusIndex(data);
  }, []);

  // First rail list data
  useEffect(() => {
    // Is baseRailList ready
    if (baseListWithId.length > 0) {
      setDynamicRailList(baseListWithId.slice(0, config.eachTriggerPoint + 1));
    }
  }, [baseListWithId]);

  const onEndReached = useCallback(() => {
    if (focusIndex > 0 && focusIndex < baseListWithId.length) {
      setDynamicRailList((oldArray) =>
        uniqueByField(
          [
            ...oldArray,
            ...baseListWithId.slice(
              focusIndex + 1,
              focusIndex + 1 + config.eachTriggerPoint
            ),
          ],
          'id'
        )
      );
    }
  }, [baseListWithId, focusIndex]);

  return {
    onEndReached,
    dynamicRailList,
    railFocusIndexSetter,
  };
};

export default useLazyLoadOnFlatlist;
