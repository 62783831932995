import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import useLocalization from '../../../libs/localization/useLocalization';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import {
  isWeb,
  trailer,
  isValidRailType,
  windowHeight,
  isAnimationsDisabled,
  windowWidth,
} from '../../../helpers/CommonHelper';
import Background from '../../atoms/Posters/Background';
import FocusableSection from './section';
import Skeleton from './skeleton';
import styles from './styles';
import { useRoute } from '@react-navigation/native';
import { makeApiCall } from '../../../middleware/dynamic';
import Arrows from './Arrows';
import { FlashList } from '@shopify/flash-list';
import useLazyLoadOnFlatlist from '../../../hooks/useLazyLoadOnFlatlist';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useScrollFailedHandler from '../../../hooks/useScrollFailedHandler';

const offsetArr = [GetScaledValue(0), GetScaledValue(1080)];

/**
 * ContentDetail
 *
 * @returns {module:JSX.Element} -
 */
const ContentDetail = () => {
  const railListRef = useRef();
  const navigation = useCustomNavigation();
  const { onScrollToIndexFailedHandler } = useScrollFailedHandler({
    listRef: railListRef,
  });

  const { url, contentType } = useRoute().params;
  const { languageDirection } = useLocalization();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [title, setTitle] = useState(null);

  const { dynamicRailList, railFocusIndexSetter, onEndReached } =
    useLazyLoadOnFlatlist(data?.rails ?? []);

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  const keyExtractor = useCallback((_item, _index) => `detail_${_index}`, []);

  /**
   * Filters rails to include only valid railTypes
   *
   * @param {Array} rails - The array of rails to filter
   * @returns {Array} - The filtered array of rails with valid railTypes
   */
  const filterRails = (rails) => {
    return rails.filter((rail) => isValidRailType(rail.railType));
  };

  useEffect(() => {
    (async () => {
      if (url) {
        const response = await makeApiCall({ url });
        const filteredRails = filterRails(response.rails);

        setData({ ...response, rails: filteredRails });
        setTimeout(() => setIsLoading(false), 1000);
        setTimeout(() => {
          setFocusCustom('detail-0-button-0');
        }, 100);
      }
    })();
  }, [url]);

  const counter = useCallback((_data, idx) => {
    if (idx !== 0) {
      offsetArr[idx + 1] = offsetArr[idx] + GetScaledValue(_data);
    }

    return offsetArr[idx];
  }, []);

  const onBecameFocus = useCallback(
    (coordinate, selected) => {
      const player = trailer.get();

      railFocusIndexSetter(selected?.index);

      if (player) {
        if (selected.index === 0 && player.isPaused()) player.play();
        else player.pause();
      }

      setTimeout(() => {
        if (isWeb) {
          railListRef.current?.scrollToOffset({
            offset: counter(coordinate.height, selected?.index),
            animated: !isAnimationsDisabled,
          });
        } else {
          railListRef.current?.scrollToIndex({
            index: selected?.index,
            animated: !isAnimationsDisabled,
          });
        }
      }, 100);

      setTitle({
        up: data.rails[selected.index - 1]?.title,
        down: data.rails[selected.index + 1]?.title,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [counter, data]
  );

  /**
   * Render item
   */
  const renderItem = useCallback(
    ({ item, index: idx }) => {
      return (
        <FocusableSection
          item={item}
          index={idx}
          competition={{
            matchDate: data.matchDate,
            matchStartTime: data.matchStartTime,
            matchEndTime: data.matchEndTime,
            programEndTime: data.programEndTime,
            programStartTime: data.programStartTime,
          }}
          setImage={() => null}
          focusKeyPrefix={'detail'}
          contentType={contentType}
          contentLength={data?.rails?.length}
          onBecameFocused={onBecameFocus}
          navigation={navigation}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <Background blurRadius={50} source={''}>
      <View dir={languageDirection} style={styles.fullScreenContainer}>
        {isLoading && <Skeleton contentType={contentType} />}
        <FlashList
          ref={railListRef}
          scrollEnabled={false}
          data={dynamicRailList}
          renderItem={renderItem}
          extraData={data.length}
          onEndReachedThreshold={0.5}
          onEndReached={onEndReached}
          keyExtractor={keyExtractor}
          estimatedFirstItemOffset={0}
          removeClippedSubviews={true}
          drawDistance={GetScaledValue(10000)}
          showsVerticalScrollIndicator={false}
          estimatedItemSize={GetScaledValue(1000)}
          onScrollToIndexFailed={onScrollToIndexFailedHandler}
          estimatedListSize={{
            width: windowWidth,
            height: GetScaledValue(1080),
          }}
          ListFooterComponent={<View style={{ height: windowHeight }} />}
        />
        <Arrows title={title} contentType={contentType} />
      </View>
    </Background>
  );
};

export default ContentDetail;
