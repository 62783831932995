import React from 'react';
import { View, Text } from 'react-native';

/**
 * Health Check
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const HealthCheck = () => {
  return (
    <View>
      <Text nativeID="healthcheck">health check: ok</Text>
    </View>
  );
};

export default HealthCheck;
