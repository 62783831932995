import React, { useEffect, useState, useCallback } from 'react';
import { View } from 'react-native';
import { ArrowDownBold } from '../../../assets/icons';

import EventBoxes from '../../organisms/EventBoxes';
import { setFocusCustom } from '../../../helpers/FocusHelper';

import LivePlayerProgressBar from '../../organisms/LivePlayerProgressBar';

import PlayerTopContainer from '../../molecules/PlayerTopContainer';
import Overlay from '../PlayerMenuProperties/Overlay';
import ControllerContainer from '../PlayerMenuProperties/ControllerContainer';
import styles from './styles';
import PauseButton from '../PlayerMenuProperties/PauseButton';
import axios from 'axios';
import { SEEK_VALUE } from '../../../helpers/CommonHelper';
import ArrowWithText from '../../molecules/ArrowWithText';
import { GetScaledValue } from '@digiturk/screen-size';

const channelListData = [
  {
    id: '1',
    name: 'Bein HD',
    image: 'https://via.placeholder.com/150',
    programName: 'Program Name 09:00',
    nextProgramName: 'Next Program Name 10:00',
    isLive: true,
  },
  {
    id: '2',
    name: 'Bein Sports',
    image: 'https://via.placeholder.com/150',
    programName: 'Program Name 09:00',
    nextProgramName: 'Next Program Name 10:00',
    isLive: false,
  },
  {
    id: '3',
    name: 'Bein Sports 2',
    image: 'https://via.placeholder.com/150',
    programName: 'Program Name 09:00',
    nextProgramName: 'Next Program Name 10:00',
    isLive: false,
  },
  {
    id: '4',
    name: 'Bein Sports 2',
    image: 'https://via.placeholder.com/150',
    programName: 'Program Name 09:00',
    nextProgramName: 'Next Program Name 10:00',
    isLive: false,
  },
];

const buttonPrefix = 'live-player-activity-button-';
const eventBoxPrefix = 'event-boxes-';

/**
 * Property Element List
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {number} props.totalTime - totalTime
 * @param {number} props.currentTime - currentTime
 * @param {object} props.refPlayer - reference to player
 * @param {boolean} props.isPaused - isPaused
 * @param {object} props.userActivity - userActivity
 * @param {Array} props.audioList - audio list
 * @param {object} props.contentVod - audio list
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {boolean} props.isTrackLive - isTrackLive
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.onSlideDown - onSlideDown
 * @param {boolean} props.isLongPressActive - isLongPressActive
 * @returns {module:JSX.Element} - JSX.Element
 */
const ElementList = ({
  videoType,
  totalTime,
  currentTime,
  refPlayer,
  isPaused,
  userActivity,
  audioList,
  contentVod,
  subtitleSettingsCallback,
  isTrackLive,
  onEnterPress,
  onSlideDown,
  isLongPressActive,
}) => {
  const opacity = userActivity ? 1 : 0;

  const [commentaries, setCommentaries] = useState([]);
  const [mutatedCurrentTime, setMutatedCurrentTime] = useState(currentTime);

  useEffect(() => {
    setFocusCustom('progress-bar');
  }, []);

  useEffect(() => {
    axios
      .get('https://caladan.tod2-test.beiniz.biz/api/v22/play/commentary')
      .then((response) => {
        setCommentaries(response.data.data.CommentInfo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const onEnterGoLiveButton = useCallback(() => {
    (async () => {
      setMutatedCurrentTime(0);
      await refPlayer.current.timeShift(0);
      setFocusCustom('hidden-focus-placeholder');
    })();
  }, [refPlayer]);

  const seekForward = useCallback(() => {
    if (userActivity) {
      setMutatedCurrentTime((prev) => prev + Math.floor(SEEK_VALUE));
    }
  }, [userActivity]);

  useEffect(() => {
    (async () => {
      if (!isLongPressActive) {
        // TODO: Block when requested time higher or lower than stream limits
        refPlayer?.current?.timeShift(mutatedCurrentTime);
      }
    })();
  }, [refPlayer, mutatedCurrentTime, isLongPressActive]);

  const seekBackWard = useCallback(() => {
    if (userActivity) {
      setMutatedCurrentTime((prev) => prev - Math.floor(SEEK_VALUE));
    }
  }, [userActivity]);

  return (
    <View style={[styles.container, { opacity }]}>
      <PlayerTopContainer
        videoType={videoType}
        channelList={channelListData}
        handleUpgradeNavigation={() => {}}
        downFocusKey={buttonPrefix + '0'}
      />

      <View style={styles.playerMenuContainer}>
        <ControllerContainer
          videoType={videoType}
          downFocusKey="progress-bar"
          buttonPrefix={buttonPrefix}
          contentInfo={contentVod}
          audioList={audioList}
          qualityList={null}
          setSelectedSubtitle={() => null}
          handleAudioSelect={() => null}
          handleMoreContentNavigation={() => null}
          subtitleSettingsCallback={subtitleSettingsCallback}
          userActivity={userActivity}
          showSkipButton={false}
          isTrackLive={isTrackLive}
          onEnterGoLiveButton={onEnterGoLiveButton}
        />

        <LivePlayerProgressBar
          videoType={videoType}
          refPlayer={refPlayer}
          totalTime={totalTime}
          currentTime={currentTime + mutatedCurrentTime}
          isPaused={isPaused}
          onEnterPress={onEnterPress}
          focusKey="progress-bar"
          upFocusKey={isTrackLive ? buttonPrefix + '0' : 'goLive'}
          downFocusKey={
            commentaries.length ? eventBoxPrefix + '0' : onSlideDown
          }
          rightFocusKey={seekForward}
          leftFocusKey={seekBackWard}
          isTrackLive={isTrackLive}
        />

        <EventBoxes
          prefix={eventBoxPrefix}
          downFocusKey={onSlideDown}
          commentaries={commentaries}
        />

        <ArrowWithText
          containerStyle={{ marginTop: GetScaledValue(40) }}
          icon={ArrowDownBold}
          text="Statistics & Commentary"
          iconStyle={styles.arrowIcon}
        />
      </View>

      {isPaused && <PauseButton />}
      <Overlay userActivity={userActivity} />
    </View>
  );
};

/**
 * Property Main
 *
 * @param {object} props - props
 * @param {string} props.videoType - videoType
 * @param {number} props.totalTime - totalTime
 * @param {number} props.currentTime - currentTime
 * @param {object} props.refPlayer - reference to player
 * @param {object} props.seekRef - seekRef reference
 * @param {boolean} props.isPaused - isPaused
 * @param {Function} props.setIsPaused - set isPaused
 * @param {object} props.userActivity - userActivity
 * @param {object} props.audioList - playerAudio Data
 * @param {object} props.contentVod - playerAudio Data
 * @param {Function} props.navigate - navigate
 * @param {object} props.subtitleList - subtitleList
 * @param {Function} props.subtitleSettingsCallback - subtitleSettingsCallback
 * @param {boolean} props.isTrackLive - isTrackLive
 * @param {Function} props.onEnterPress - onEnterPress
 * @param {Function} props.onSlideDown - onSlideDown
 * @param {boolean} props.isLongPressActive - isLongPressActive
 * @returns {module:JSX.Element} - JSX.Element
 */
const PropertyMain = ({
  videoType,
  totalTime,
  currentTime,
  refPlayer,
  seekRef,
  isPaused,
  setIsPaused,
  userActivity,
  audioList,
  navigate,
  contentVod,
  subtitleList,
  subtitleSettingsCallback,
  isTrackLive,
  onEnterPress,
  onSlideDown,
  isLongPressActive,
}) => {
  return (
    <ElementList
      videoType={videoType}
      totalTime={totalTime}
      currentTime={currentTime}
      refPlayer={refPlayer}
      seekRef={seekRef}
      isPaused={isPaused}
      setIsPaused={setIsPaused}
      userActivity={userActivity}
      audioList={audioList}
      navigate={navigate}
      contentVod={contentVod}
      subtitleList={subtitleList}
      subtitleSettingsCallback={subtitleSettingsCallback}
      isTrackLive={isTrackLive}
      onEnterPress={onEnterPress}
      onSlideDown={onSlideDown}
      isLongPressActive={isLongPressActive}
    />
  );
};

export default React.memo(PropertyMain);
