import React from 'react';
import { StyleSheet } from 'react-native';
import GenericModal from '../../molecules/GenericModal';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { GetScaledValue } from '@digiturk/screen-size';
import { ApiRels, FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import colors from '../../../helpers/Colors';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { useRoute } from '@react-navigation/native';
import { getLink } from '../../../helpers/CommonHelper';
import { makeApiCall } from '../../../middleware/dynamic';
import { profileActions, useProfile } from '../../../context';
import { authActions, useAuth } from '../../../context/Auth';

/**
 * SignOut Settings Modal
 *
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const SignOut = () => {
  const data = useRoute().params.data;
  const { authDispatch } = useAuth();
  const { profileDispatch } = useProfile();
  const { goBack, replaceNavigate } = useCustomNavigation();

  const buttons = [
    {
      text: 'Cancel',
      onEnterPress: () => goBack(),
    },
    {
      text: 'Sign Out',
      onEnterPress: async () => {
        const { url, method } = getLink(data, ApiRels.ACCOUNT.LOGOUT);

        const response = await makeApiCall({ url, method });

        if (response?.isSuccess) {
          authDispatch(authActions.setAuth(null));
          profileDispatch(profileActions.setSelectedProfile(null));
          replaceNavigate(NavigationRoutes.landing);
        }
      },
    },
  ];

  return (
    <GenericModal
      title="Are you sure you want to sign out?"
      message="Signing out will end your session. Make sure you’ve saved everything you need. 
      You can always sign back in to continue where you left off."
      buttons={buttons}
      textContainerHeader={styles.textContainerHeader}
      textContainerContent={styles.textContainerContent}
      buttonStyle={styles.buttonStyle}
      buttonContainerStyle={styles.buttonContainerStyle}
      pageStyle={styles.page}
      titleOptions={styles.titleOptions}
      contentOptions={styles.contentOptions}
      onHardwareBackPress={goBack}
    />
  );
};

export default SignOut;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainerHeader: {
    paddingBottom: GetScaledValue(20),
  },
  titleOptions: {
    fontFamily: FONT_FAMILY.SEMIBOLD,
    size: FONT_SIZES.HEADING1,
  },
  textContainerContent: {
    paddingBottom: GetScaledValue(60),
    width: GetScaledValue(1100),
    textAlign: 'center',
  },
  contentOptions: {
    fontFamily: FONT_FAMILY.REGULAR,
    size: FONT_SIZES.HEADING3,
    color: colors.neutral300,
  },
  buttonContainerStyle: {
    paddingBottom: GetScaledValue(8),
  },
  buttonStyle: {
    width: GetScaledValue(400),
    height: GetScaledValue(80),
    borderRadius: GetScaledValue(12),
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});
