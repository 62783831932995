import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import NavigationRoutes from './NavigationRoutes';

// Screens
import Splash from '../components/pages/Splash';
import Home from '../components/pages/Home';
import ContentDetail from '../components/pages/Detail';
import TvShows from '../components/pages/TvShows';
import Movies from '../components/pages/Movies';
import Video from '../components/pages/Video';
import Mylist from '../components/pages/Mylist';
import Sports from '../components/pages/Sports';
import MoreEpisodes from '../components/pages/MoreEpisodes';
import FourKQR from '../components/templates/FourKQR';
import Live from '../components/pages/Live';
import LiveTv from '../components/pages/LiveTv';
import LongPause from '../components/organisms/LongPause';
import Profiles from '../components/pages/Profiles';
import ProfileLogin from '../components/pages/ProfileLogin';
import ViewAll from '../components/pages/ViewAll';
import ManageProfile from '../components/pages/ManageProfile';
import ModalLanguageSelection from '../components/pages/ModalLanguageSelection';
import Delete from '../components/pages/ManageProfile/Delete/Delete';
import Settings from '../components/pages/Settings';
import SignOut from '../components/pages/Settings/SignOut';
import Search from '../components/pages/Search';
import AddProfile from '../components/pages/ManageProfile/AddProfile';
import GeneralError from '../components/templates/GeneralError';
import SignIn from '../components/pages/SignIn';
import Landing from '../components/pages/Landing';
import ModalExit from '../components/pages/ModalExit';
import DevModeLogin from '../components/pages/DevModeLogin';
import DevMode from '../components/pages/DevMode';
import LiveToVod from '../components/pages/LiveToVod';
import Kids from '../components/pages/Kids';

const Stack = createStackNavigator();

/**
 * Transition config
 */
const config = {
  animation: 'spring',
  config: {
    stiffness: 1000,
    damping: 500,
    mass: 3,
    overshootClamping: true,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};

/**
 * NavigationOptions for transparent Stack.Screen
 */
const navigationOptions = {
  cardStyle: { backgroundColor: 'transparent' },
  headerShown: false,
  transitionSpec: {
    open: config,
    close: config,
  },
};

/**
 * Create react navigation routers
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const AppNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName={NavigationRoutes.splash}
      screenOptions={{
        freezeOnBlur: true,
        presentation: 'transparentModal',
        animationEnabled: false,
      }}>
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.signIn}
        component={SignIn}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.generalError}
        component={GeneralError}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.landing}
        component={Landing}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.fourk}
        component={FourKQR}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.splash}
        component={Splash}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.home}
        component={Home}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.sports}
        component={Sports}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.movies}
        component={Movies}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.mylist}
        component={Mylist}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.tvshows}
        component={TvShows}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.contentdetail}
        component={ContentDetail}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.video}
        component={Video}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.moreEpisodes}
        component={MoreEpisodes}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.live}
        component={Live}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.livetv}
        component={LiveTv}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.longPause}
        component={LongPause}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.profiles}
        component={Profiles}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.profilelogin}
        component={ProfileLogin}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.viewAll}
        component={ViewAll}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.manageprofile}
        component={ManageProfile}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.addprofile}
        component={AddProfile}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.modalLanguageSelection}
        component={ModalLanguageSelection}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.delete}
        component={Delete}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.settings}
        component={Settings}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.signOut}
        component={SignOut}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.search}
        component={Search}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.liveToVod}
        component={LiveToVod}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.exit}
        component={ModalExit}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.kids}
        component={Kids}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.devModeLogin}
        component={DevModeLogin}
      />
      <Stack.Screen
        options={navigationOptions}
        name={NavigationRoutes.devMode}
        component={DevMode}
      />
    </Stack.Navigator>
  );
};

export default AppNavigator;
