import React, { memo, Suspense, useMemo, useState, useEffect } from 'react';
import { View } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import { makeApiCall } from '../../../middleware/dynamic';
import {
  getLink,
  getMatchStatus,
  isFullScreenSection,
  railInitialData,
} from '../../../helpers/CommonHelper';
import { ApiRels, contentTypeList } from '../../../helpers/Enums';
import SelectedSectionComponent from '../../molecules/SelectedComponent/Section';

/**
 * Fetches the rail content data based on the given item.
 *
 * @param {object} item - The rail item for which the data is fetched.
 * @returns {object} - The fetched rail content data.
 */
const useRailContentData = (item) => {
  const [railContentData, setRailContentData] = useState(railInitialData);

  useEffect(() => {
    /**
     * Fetches the data for the given item.
     *
     * @returns {Promise<void>} - The fetched data.
     */
    const fetchData = async () => {
      if (!item) return;

      const { url } = getLink(item, ApiRels.SELF);
      const response = await makeApiCall({ url });

      if (response) {
        setRailContentData(response);
      }
    };

    fetchData();
  }, [item]);

  return railContentData;
};

/**
 * Calculates the container style based on the item and its configuration.
 *
 * @param {object} item - The rail item.
 * @param {number} contentType - The content type.
 * @param {boolean} hasFocusedChild - The hasFocusedChild flag.
 * @returns {object} - The calculated container style.
 */
const useContainerStyle = (item, contentType, hasFocusedChild) => {
  return useMemo(
    () => ({
      paddingTop: isFullScreenSection(item.railType)
        ? 0
        : contentType === 3
        ? GetScaledValue(272)
        : GetScaledValue(192),
      opacity: hasFocusedChild ? 1 : 0.6,
    }),
    [contentType, hasFocusedChild, item.railType]
  );
};

/**
 * Determines the correct rail component based on the rail type.
 *
 * @param {object} item - The rail item.
 * @returns {React.Component} - The selected component for the rail type.
 */
const useSelectedComponent = (item) => {
  return useMemo(() => {
    return (
      SelectedSectionComponent[item.railType] ||
      SelectedSectionComponent.default
    );
  }, [item]);
};

/**
 * Gets the match status based on the competition info.
 *
 * @param {object} competitionInfo - The competition info object.
 * @param {object} contentType - The contentType
 * @returns {string} - The match status.
 */
const useGetCompetitonInfo = (competitionInfo, contentType) => {
  return useMemo(() => {
    if (contentType !== contentTypeList.GAME) return null;

    return {
      ...competitionInfo,
      matchStatus: getMatchStatus(competitionInfo),
    };
  }, [competitionInfo, contentType]);
};

/**
 * Renders a section component.
 *
 * @param {object} props - The component props.
 * @param {object} props.item - The item object.
 * @param {number} props.index - The index of the item.
 * @param {string} props.focusKeyPrefix - The focusKeyPrefix string.
 * @param {Function} props.setGlobalFocusedItem - The setGlobalFocusedItem function.
 * @param {Function} props.setImage - The setImage function.
 * @param {string|boolean} props.hasFocusedChild - hasFocusedChild
 * @param {number} props.contentLength - The content length.
 * @param {number} props.contentType - The content type.
 * @param {object} props.navigation - navigation
 * @param {object} props.competition - competition
 * @returns {module:JSX.Element} The rendered section component.
 */
const Section = ({
  item,
  index,
  focusKeyPrefix,
  contentLength,
  setGlobalFocusedItem,
  setImage,
  hasFocusedChild,
  contentType,
  navigation,
  competition,
}) => {
  const railContentData = useRailContentData(item);
  const containerStyle = useContainerStyle(item, contentType, hasFocusedChild);
  const SelectedComponent = useSelectedComponent(item);
  const competitionInfo = useGetCompetitonInfo(competition, contentType);

  const upFocusKey = index > 0 ? `${focusKeyPrefix}-${index - 1}` : false;
  const downFocusKey =
    index < contentLength - 1 ? `${focusKeyPrefix}-${index + 1}` : false;

  return (
    <View style={containerStyle}>
      <Suspense>
        <SelectedComponent
          title={item.title}
          competitionInfo={competitionInfo}
          railContentData={railContentData}
          contentType={contentType}
          index={index}
          focusKeyPrefix={`${focusKeyPrefix}-${index}`}
          focusKey={`${focusKeyPrefix}-${index}`}
          setGlobalFocusedItem={setGlobalFocusedItem}
          upFocusKey={upFocusKey}
          downFocusKey={downFocusKey}
          setImage={setImage}
          railType={item.railType}
          railOffsetStartOfPage={0}
          railOffsetInlineStart={140}
          railWidth={1920}
          railItemGap={40}
          navigation={navigation}
        />
      </Suspense>
    </View>
  );
};

export default memo(withFocusable({ trackChildren: true })(Section));
