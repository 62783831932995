import { getStorage } from '../../context/storage';
import storageKeys from '../../context/storageKeys';
import api from './api';
import env from './config';
import { getApiMode } from '../../helpers/CommonHelper';

export const AppApis = {
  MenuApis: async () => {
    // api mode check from device storage
    const apiMode = await getApiMode();

    if (__DEV__) {
      console.info('* api mode:', apiMode);
    }

    // auth key check from device storage
    const token = await getStorage(storageKeys.auth);

    if (token && undefined !== token.at) {
      api.defaults.headers.Authorization = `Bearer ${token.at}`;
    }

    const baseURL =
      env.baseUrls[apiMode].api + 'api/v' + env.baseUrls[apiMode].apiVersion;

    return api.get(baseURL + '/ui/menus');
  },
  DynamicGet: (url) => api.get(url),
  DynamicPost: (url, body, headers) => api.post(url, body, headers),
  DynamicPut: (url, body, headers) => api.put(url, body, headers),
  DynamicDelete: (url) => api.delete(url),
};
