import { GetWindowWidth, GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';

const styles = StyleSheet.create({
  heroContainer: {
    width: GetScaledValue(1920),
    height: GetScaledValue(1080),
    backgroundColor: colors.shades100,
  },
  imageContainer: {
    flex: 1,
  },
  image: {
    width: GetScaledValue(2321),
    height: GetScaledValue(1702),
    position: 'absolute',
    left: GetScaledValue(-200),
    opacity: 0.5,
    backgroundColor: colors.shades100,
  },
  gradient: {
    width: GetScaledValue(1920),
    height: GetScaledValue(630),
    position: 'absolute',
  },
  container: {
    width: GetWindowWidth(1920),
    alignItems: 'center',
    position: 'absolute',
    gap: GetScaledValue(20),
  },
  topContainer: {
    alignSelf: 'flex-start',
    height: GetScaledValue(102),
    paddingTop: GetScaledValue(60),
    paddingRight: GetScaledValue(80),
    paddingLeft: GetScaledValue(80),
  },
  bottomContainer: {
    width: GetScaledValue(1760),
    height: GetScaledValue(803),
    gap: GetScaledValue(140),
  },
  signInText: {
    gap: GetScaledValue(20),
    alignItems: 'center',
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: GetScaledValue(40),
  },
  leftCardContainer: {
    gap: GetScaledValue(40),
  },
  card: {
    textAlign: 'center',
    width: GetScaledValue(818),
    height: GetScaledValue(536),
    padding: GetScaledValue(40),
    borderRadius: GetScaledValue(20),
    backgroundColor: colors.blackTransparent64,
    backdropFilter: 'blur(3px)',
    borderWidth: 2,
    borderColor: colors.whiteTransparent16,
    gap: GetScaledValue(140),
  },
  card2: {
    gap: GetScaledValue(40),
    paddingStart: GetScaledValue(80),
    paddingEnd: GetScaledValue(80),
  },
  qrImage: {
    width: GetScaledValue(320),
    height: GetScaledValue(320),
    alignSelf: 'center',
  },
  linkText: {
    paddingStart: GetScaledValue(40),
    paddingEnd: GetScaledValue(40),
    paddingTop: GetScaledValue(20),
    paddingBottom: GetScaledValue(20),
    textAlign: 'center',
  },
  alignText: {
    textAlign: 'center',
  },
  alignMid: {
    alignSelf: 'center',
  },
  backButton: {
    width: GetScaledValue(100),
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 0,
    paddingVertical: 0,
    backgroundColor: colors.transparent,
  },
  flatlist: {
    alignItems: 'center',
  },
  leftCardBottom: {
    gap: GetScaledValue(40),
  },
  flatListContainer: {
    width: GetScaledValue(105),
    height: GetScaledValue(105),
    paddingHorizontal: GetScaledValue(10),
    paddingVertical: GetScaledValue(10),
    marginHorizontal: GetScaledValue(5),
    backgroundColor: colors.whiteTransparent08,
    alignItems: 'center',
    borderRadius: GetScaledValue(20),
  },
  flatListItem: {
    paddingHorizontal: GetScaledValue(30),
  },
  copyright: {
    width: GetScaledValue(1760),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: GetScaledValue(20),
  },
  containerCard: {
    width: GetScaledValue(1066),
    height: GetScaledValue(348),
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    gap: GetScaledValue(60),
    backgroundColor: colors.blackTransparent64,
    backdropFilter: 'blur(3px)',
    borderWidth: 2,
    borderColor: colors.whiteTransparent16,
  },
  renewButton: {
    alignSelf: 'center',
  },
});

export default styles;
