import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import useLocalization from '../../../libs/localization/useLocalization';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';

import FocusableSidebar from '../../templates/Sidebar';

import { IndexPropTypes } from './proptypes';
import styles from './styles';
import PageContent from '../../templates/PageContent';
import useCustomNavigation from '../../../hooks/useCustomNavigation';

/**
 * ViewAll screen
 *
 * @param {object} props - props
 * @param {object} props.route - route
 * @returns {module:JSX.Element} - JSX.Element
 */
const ViewAll = ({ route }) => {
  const [date, setDate] = useState(0);

  const { languageDirection } = useLocalization();
  const navigation = useCustomNavigation();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  useEffect(() => {
    if (route?.params?.date) {
      setDate(route?.params?.date);
    }
  }, [route]);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`viewall-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'all-content'}
        navigation={navigation}
      />
      <PageContent
        key={'viewall'}
        prefix={'all-raillist'}
        focusKey={'all-content'}
        leftFocusKey={'sidebar'}
        viewAllUrl={route?.params?.url}
        initialFocus={'viewall-raillist-0'}
        navigation={navigation}
      />
    </View>
  );
};

ViewAll.propTypes = IndexPropTypes;
ViewAll.whyDidYouRender = false;

export default ViewAll;
