import React, { useCallback, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import { ApiRels, FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { getLink } from '../../../helpers/CommonHelper';
import { makeApiCall } from '../../../middleware/dynamic';
import timeKeeper from '../../../libs/timeKeeper';
import Image from '../../atoms/Image';

/**
 * Subscription Settings Component
 *
 * @param {object} props - props
 * @param {object} props.subs - subs
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Subscription = ({ subs }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    const fetchData = async () => {
      const { url } = getLink(subs, ApiRels.SELF);
      const response = await makeApiCall({ url });

      if (response?.subscriptions?.length > 0) {
        setData(response.subscriptions[0]);
      } else {
        setData({
          title: 'Subscription',
          emptyText:
            "Looks like you're not subscribed yet. To dive into a world of unlimited movies, TV shows, and more, take a look at our subscription plans and find the perfect one for you Find your ideal plan on our website or app.",
        });
      }
    };

    fetchData();
  }, [subs]);

  const SubscriptionDetail = useCallback(() => {
    return (
      <View style={styles.subsDetail}>
        <View style={{ height: GetScaledValue(120) }}>
          <Text
            text={data?.offerDetail?.description}
            size={FONT_SIZES.HEADING4}
            fontFamily={FONT_FAMILY.REGULAR}
            color={colors.neutral300}
            multiline
            numberOfLines={4}
          />
        </View>
        {/* <View style={styles.warning}>
          <Icon
            icon={Warning}
            width={40}
            height={40}
            style={styles.warningIcon}
            color={colors.primary500}
          />
          <Text
            text={"Unfortunately, we can't process your payment."}
            size={FONT_SIZES.HEADING5}
            fontFamily={FONT_FAMILY.SEMIBOLD}
            color={colors.primary400}
          />
        </View> */}
        <Text
          text={
            'Subscription end date:' +
            timeKeeper(data?.offerDetail?.toDate).fullDate
          }
          size={FONT_SIZES.HEADING5}
          fontFamily={FONT_FAMILY.SEMIBOLD}
        />
      </View>
    );
  }, [data]);

  const EmptyText = useCallback(() => {
    return (
      <View style={styles.emptyContainer}>
        <Text
          text={data?.emptyText}
          size={FONT_SIZES.HEADING4}
          fontFamily={FONT_FAMILY.REGULAR}
          color={colors.neutral200}
          multiline
          numberOfLines={4}
        />
      </View>
    );
  }, [data]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text
          text={subs?.title}
          size={FONT_SIZES.HEADING3}
          fontFamily={FONT_FAMILY.SEMIBOLD}
        />
        <View style={styles.iconContainer}>
          <Image
            source={data?.offerDetail?.cardIcon?.url}
            style={{ width: GetScaledValue(60), height: GetScaledValue(60) }}
          />
        </View>
      </View>
      {data?.offerDetail ? <SubscriptionDetail /> : <EmptyText />}
    </View>
  );
};

export default Subscription;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.neutral800,
    padding: GetScaledValue(40),
    borderRadius: GetScaledValue(20),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: GetScaledValue(30),
  },
  iconContainer: {
    flexDirection: 'row',
  },
  button: {
    textAlign: 'center',
    borderRadius: GetScaledValue(15),
    width: GetScaledValue(71),
    height: GetScaledValue(60),
    backgroundColor: colors.primary,
    marginLeft: GetScaledValue(15),
    paddingTop: GetScaledValue(7.5),
  },
  warningIcon: {
    color: colors.primary,
  },
  warning: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: GetScaledValue(20),
    gap: GetScaledValue(20),
    height: GetScaledValue(40),
  },
  subsDetail: {
    flex: 1,
    justifyContent: 'space-between',
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
});
