import TYPES from './types';

/**
 * App Reducer
 *
 * @param {object} state - state
 * @param {object} action - action
 * @returns {object} - reducer object
 */
const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.INIT:
      return {
        ...state,
        appState: {
          ...state.appState,
          isInitiated: true,
        },
      };

    case TYPES.SET_LANGUAGE_DIRECTION:
      return {
        ...state,
        appState: {
          ...state.appState,
          languageDirection: action.payload,
        },
      };
    case TYPES.SET_API_MODE_STATE:
      return {
        ...state,
        appState: {
          ...state.appState,
          apiMode: action.payload,
        },
      };
    default:
      throw new Error();
  }
};

export default reducer;
