import { useCallback } from 'react';
import { isAnimationsDisabled } from '../helpers/CommonHelper';

/**
 * Scroll failed handler
 *
 * @param {object} props - props
 * @param {object} props.listRef - listRef
 * @returns {object} - return object
 */
const useScrollFailedHandler = ({ listRef }) => {
  const onScrollToIndexFailedHandler = useCallback(
    ({ index, averageItemLength }) => {
      listRef?.current?.scrollToOffset({
        offset: index * averageItemLength,
        animated: !isAnimationsDisabled,
      });

      setTimeout(() => {
        listRef?.current?.scrollToIndex({
          index: index,
          animated: !isAnimationsDisabled,
        });
      }, 100);
    },
    [listRef]
  );

  return { onScrollToIndexFailedHandler };
};

export default useScrollFailedHandler;
