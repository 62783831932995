import { GetScaledValue } from '@digiturk/screen-size';
import { StyleSheet } from 'react-native';
import colors from '../../../helpers/Colors';
import { windowHeight } from '../../../helpers/CommonHelper';

const styles = StyleSheet.create({
  fullScreenContainer: {
    height: windowHeight,
    backgroundColor: colors.blackTransparent72,
  },
  upArrowStyle: {
    position: 'absolute',
    zIndex: 3,
    top: GetScaledValue(60),
    paddingStart: GetScaledValue(140),
  },
  iconStyle: {
    color: colors.shades00,
    width: 40,
    height: 40,
  },
  iconArea: {
    position: 'absolute',
    zIndex: 3,
    top: GetScaledValue(60),
    end: GetScaledValue(140),
  },
  logo: {
    width: GetScaledValue(265),
    height: GetScaledValue(120),
  },
  downArrowStyle: {
    position: 'absolute',
    zIndex: 3,
    bottom: GetScaledValue(60),
    paddingStart: GetScaledValue(140),
  },
  loading: {
    position: 'absolute',
    backgroundColor: 'black',
    zIndex: 99,
    top: 0,
    bottom: 0,
    start: 0,
    right: 0,
  },
});

export default styles;
