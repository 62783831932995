import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import PageContent from '../../templates/PageContent';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useLocalization from '../../../libs/localization/useLocalization';
import FocusableSidebar from '../../templates/Sidebar';

import { IndexPropTypes } from './proptypes';
import styles from './styles';
import useCustomNavigation from '../../../hooks/useCustomNavigation';

/**
 * Movies Screen
 *
 * @param {object} props - props
 * @param {object} props.route - route
 * @returns {module:JSX.Element} - JSX.Element
 */
const Movies = ({ route }) => {
  const [date, setDate] = useState(0);
  const { languageDirection } = useLocalization();
  const navigation = useCustomNavigation();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  useEffect(() => {
    if (route?.params?.date) {
      setDate(route?.params?.date);
    }
  }, [route]);

  return (
    <View dir={languageDirection} style={styles.pageArea}>
      <FocusableSidebar
        key={`movies-${date}-sidebar`}
        focusKey={'sidebar'}
        rightFocusKey={'movies-content'}
        urlSlug={'movies'}
        navigation={navigation}
      />
      <PageContent
        key={'movies'}
        urlSlug={'movies'}
        prefix={'movies-raillist'}
        focusKey={'movies-content'}
        leftFocusKey={'sidebar'}
        navigation={navigation}
      />
    </View>
  );
};

Movies.propTypes = IndexPropTypes;
Movies.whyDidYouRender = false;

export default Movies;
