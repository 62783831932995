import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';
import colors from '../../../helpers/Colors';
import Text from '../../atoms/Text';
import { ApiRels, FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import Icon from '../../atoms/Icon';
import { LiveTVStroke, PhoneStroke, TabletStroke } from '../../../assets/icons';
import { makeApiCall } from '../../../middleware/dynamic';
import { getLink } from '../../../helpers/CommonHelper';

/**
 * Icon List
 * Select icons by api iconName key
 */
const iconList = {
  desktop: LiveTVStroke,
  Tablet: TabletStroke,
  Phone: PhoneStroke,
};

/**
 * Devices Settings Component
 *
 * @param {object} props - props
 * @param {Array} props.devices - devices
 * @returns {module:JSX.Element} - module:JSX.Element
 */
const Devices = ({ devices }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line require-jsdoc
    const fetchData = async () => {
      const { url } = getLink(devices, ApiRels.SELF);
      const response = await makeApiCall({ url });

      response && setData(response.devices);
    };

    fetchData();
  }, [devices]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text
          text={devices?.title}
          size={FONT_SIZES.HEADING3}
          fontFamily={FONT_FAMILY.SEMIBOLD}
        />
      </View>
      <View style={styles.devicesContainer}>
        {data.length === 0 && (
          <Text
            text={
              "We couldn't find any registered devices for this account. Please activate your account by adding a device or updating an existing one"
            }
            size={FONT_SIZES.HEADING4}
            fontFamily={FONT_FAMILY.REGULAR}
            color={colors.neutral200}
          />
        )}
        {data.map((item, index) => (
          <View key={index} style={styles.device}>
            <Icon
              icon={iconList[item.osType]}
              width={40}
              height={40}
              color={item.isThisDevice ? colors.primary500 : colors.neutral300}
            />
            <Text
              text={item.manufacturerName}
              size={FONT_SIZES.HEADING5}
              fontFamily={FONT_FAMILY.REGULAR}
              color={colors.neutral300}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

export default Devices;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.neutral800,
    padding: GetScaledValue(40),
    height: GetScaledValue(224),
    borderRadius: GetScaledValue(20),
  },
  header: {
    marginBottom: GetScaledValue(20),
  },
  devicesContainer: {
    flexDirection: 'row',
    gap: GetScaledValue(40),
  },
  device: {
    alignItems: 'center',
    width: GetScaledValue(140),
    height: GetScaledValue(84),
    gap: GetScaledValue(20),
  },
});
