import React, { useState, useCallback } from 'react';
import { View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import styles from './styles';
import Text from '../../atoms/Text';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import { setFocusCustom } from '../../../helpers/FocusHelper';

import useCustomNavigation from '../../../hooks/useCustomNavigation';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import Keyboard from '../../organisms/Keyboard';
import FakeInput from '../../molecules/FakeInput';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';

/**
 * Developer Mode Login Page
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const DevModeLogin = () => {
  const [password, setPassword] = useState('');
  const { navigate, goBack } = useCustomNavigation();

  useHardwareBackPress(() => {
    goBack();
  }, true);

  useFocusEffect(
    useCallback(() => {
      setFocusCustom(`password-keyboard-0`);
    }, [])
  );

  /**
   * onEnterPress Accept button
   */
  const onEnterPressAccept = () => {
    if (password === 'b2809n') {
      setFocusCustom('clear');
      navigate(NavigationRoutes.devMode);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text
          text="Developer Mode Login"
          size={FONT_SIZES.HEADING1}
          fontFamily={FONT_FAMILY.SEMIBOLD}
          align="center"
        />

        <View style={styles.innerContainer}>
          <Keyboard
            limit={6}
            value={password}
            setValue={setPassword}
            prefix={`password-keyboard`}
            onDonePress={onEnterPressAccept}
            containerStyle={styles.keyboardContainer}
          />
          <View style={styles.inputContainer}>
            <FakeInput
              value={password}
              maxChar={24}
              label={'Password'}
              fakeFocus={password.length}
              inputContainer={styles.input}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default DevModeLogin;
