import { View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import SwitchProfile from '../../organisms/SwitchProfile';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import useLocalization from '../../../libs/localization/useLocalization';
import NavigationRoutes from '../../../navigation/NavigationRoutes';
import { profileActions, useProfile } from '../../../context';
import { ProfileTypes } from '../../../helpers/Enums';
import {
  useFocusEffect,
  useIsFocused,
  useRoute,
} from '@react-navigation/native';
import { makeApiCall } from '../../../middleware/dynamic';

/**
 *
 * @returns {module:JSX.Element} -
 */
const Profiles = () => {
  const isFocused = useIsFocused();
  const url = useRoute()?.params?.url;
  const { navigate } = useCustomNavigation();

  const { languageDirection } = useLocalization();
  const { profileState, profileDispatch } = useProfile();

  const { profiles, profileLimit, selectedProfile } = profileState;

  const [data, setData] = useState([]);

  useHardwareBackPress(() => {
    //BUG: Loosing focus when we go back because sidebar is not active
    //BUG: We have not depth of focus yet

    if (selectedProfile?.profileType === ProfileTypes.RESTRICTED) {
      //INFO: Home needs url params but this one should be goBack()
      navigate(NavigationRoutes.home, {}, 'home-raillist-0');
    }
  }, true);

  /**
   * Fetches profiles and updates the profile response state.
   *
   * @returns {void} -
   */
  const getProfiles = async () => {
    if (url) {
      const response = await makeApiCall({ url });

      response && profileDispatch(profileActions.setProfiles(response));
    }
  };

  useFocusEffect(
    useCallback(() => {
      getProfiles();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])
  );

  useEffect(() => {
    if (profiles?.length && isFocused) {
      const response = [...profiles];

      if (response.length < profileLimit) {
        response.push({ isAddProfile: true, profileName: 'Add Profile' });
      }

      setData(response);
      setFocusCustom('profile-0');
    }
  }, [isFocused, profiles, profileLimit]);

  return (
    <View style={styles.container} dir={languageDirection}>
      <SwitchProfile data={data} navigate={navigate} />
    </View>
  );
};

export default Profiles;
