import { StyleSheet, Platform } from 'react-native';
import { GetScaledValue } from '@digiturk/screen-size';

import colors from '../../../helpers/Colors';

/**
 * PlayerMenuProperties styles
 *
 * @returns {object} - styles
 */

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: GetScaledValue(0),
    top: GetScaledValue(0),
    right: GetScaledValue(0),
    left: GetScaledValue(0),
  },
  background: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.64)',
    blurRadius: 10,
    zIndex: -1,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  box: {
    width: GetScaledValue(880),
    height: GetScaledValue(384),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    zIndex: 999,
  },
  size: {
    width: GetScaledValue(420),
    alignItems: 'center',
    display: 'flex',
  },
  color: {
    width: GetScaledValue(420),
    alignItems: 'center',
    display: 'flex',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: GetScaledValue(8),
    marginBottom: GetScaledValue(2),
    marginTop: GetScaledValue(2),
    width: '100%',
    height:
      Platform?.OS === 'android' ? GetScaledValue(80) : GetScaledValue(70),
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: GetScaledValue(40),
    top: GetScaledValue(60),
    right: GetScaledValue(80),
    left: GetScaledValue(80),
    // zIndex: 1,
    position: 'absolute',
    zIndex: 999,
  },
  subTitle: {
    position: 'absolute',
    bottom: GetScaledValue(60),
    zIndex: 999,
  },
  white: {
    color: colors.white,
  },
  blackWhite: {
    width: GetScaledValue(308),
    height: GetScaledValue(50),
    borderRadius: GetScaledValue(12),
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  borderWhite: {
    width: GetScaledValue(308),
    height: GetScaledValue(50),
    borderRadius: GetScaledValue(12),
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    borderColor: 'white',
    borderWidth: 1,
  },
  whiteBlack: {
    width: GetScaledValue(308),
    height: GetScaledValue(50),
    borderRadius: GetScaledValue(12),
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
