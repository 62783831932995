import React from 'react';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';
import useCustomNavigation from '../../../hooks/useCustomNavigation';
import { exitApp } from '../../../helpers/CommonHelper';
import GenericModal from '../../molecules/GenericModal';
import styles from './styles';

/**
 * Exit Page
 *
 * @returns {module:JSX.Element} - JSX.Element
 */
const ModalExit = () => {
  const navigation = useCustomNavigation();

  useHardwareBackPress(() => {
    navigation.goBack();
  }, true);

  const buttons = [
    {
      text: 'Cancel',
      onEnterPress: () => navigation.goBack(),
    },
    {
      text: 'Exit',
      onEnterPress: () => exitApp(),
    },
  ];

  return (
    <GenericModal
      title={'Are you sure you want to exit the app?'}
      buttons={buttons}
      pageStyle={styles.page}
      buttonContainerStyle={styles.buttonContainer}
      buttonStyle={styles.button}
      textContainerHeader={styles.textContainer}
      titleOptions={styles.titleOptions}
    />
  );
};

export default ModalExit;
