import { ImageBackground, View } from 'react-native';
import React, { useCallback, useEffect } from 'react';
import styles from './styles';
import { BasicButton, buttonSize, buttonThemes } from '../../molecules/buttons';
import Text from '../../atoms/Text';
import colors from '../../../helpers/Colors';
import { FONT_FAMILY, FONT_SIZES } from '../../../helpers/Enums';
import image from '../../../assets/images/png/leagueBackgrounds/championsleague.png';
import TextWithDots from '../../molecules/TextWithDots';
import LinearGradient from 'react-native-linear-gradient';
import { setFocusCustom } from '../../../helpers/FocusHelper';
import { useHardwareBackPress } from '../../../hooks/useHardwareBackPress';

// will be deleted
const data = {
  title: 'Halloween Kills',
  year: '2021',
  rate: 'R',
  genre: 'Horror, Thriller',
  duration: '1h 59m',
};

/**
 * Long Pause component
 *
 * @param {Function} handleModal - handleModal
 * @returns {module:JSX.Element} - JSX.Element
 */
const LongPause = ({ handleModal }) => {
  useEffect(() => {
    setFocusCustom('continue-watching-button');
  }, []);

  useHardwareBackPress(() => {
    handleModal(null);
    setFocusCustom('progress-bar');
  }, true);

  /**
   * onNavigateBack
   */
  const onEnterPress = useCallback(() => {
    handleModal(null);
    setFocusCustom('progress-bar');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ImageBackground source={image} style={styles.container}>
      <LinearGradient
        colors={[colors.blackTransparent64, colors.blackTransparent64]}
        style={styles.container}>
        <View style={styles.topContainer}>
          <Text
            text="Paused"
            fontFamily={FONT_FAMILY.REGULAR}
            size={FONT_SIZES.HEADING2}
            bold={true}
            color={colors.primary500}
          />
        </View>
        <View>
          <View style={styles.bottomContainer}>
            <View style={styles.innerContainer}>
              <Text
                text={data.title}
                fontFamily={FONT_FAMILY.SEMIBOLD}
                size={FONT_SIZES.DISPLAYLARGE}
              />
              <TextWithDots
                texts={[
                  data?.releaseDate,
                  data?.rate,
                  data?.genre,
                  data?.duration,
                ]}
                fontFamily={FONT_FAMILY.REGULAR}
                size={FONT_SIZES.HEADING4}
              />
              <Text
                text={data?.summary}
                fontFamily={FONT_FAMILY.LIGHT}
                size={FONT_SIZES.HEADING3}
                style={styles.description}
                numberOfLines={4}
              />
            </View>
            <View style={styles.innerRight}>
              <BasicButton
                text="Continue Watching"
                size={buttonSize.big}
                focusKey="continue-watching-button"
                theme={buttonThemes.blackWhite}
                onEnterPress={onEnterPress}
              />
            </View>
          </View>
        </View>
      </LinearGradient>
    </ImageBackground>
  );
};

export default LongPause;
