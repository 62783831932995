import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * hook for asyncstorage
 *
 * @param {string} key - key
 * @param {string} defaultValue - defaultValue
 * @returns {object} - return
 */
const useStorage = (key, defaultValue) => {
  const [storageValue, updateStorageValue] = useState(defaultValue);

  /**
   * getStorageValue
   * Description for this function
   *
   */
  async function getStorageValue() {
    let value = defaultValue;

    try {
      value = JSON.parse(await AsyncStorage.getItem(key)) || defaultValue;
    } catch (e) {
      // console.info(e);
    } finally {
      updateStorageValue(value);
    }
  }

  /**
   * updateStorage
   * Description for this function
   *
   * @param {string} newValue - value
   */
  async function updateStorage(newValue) {
    try {
      if (newValue === null) {
        await AsyncStorage.removeItem(key);
      } else {
        const value = JSON.stringify(newValue);

        await AsyncStorage.setItem(key, value);
      }
    } catch (e) {
      //
    } finally {
      updateStorageValue(newValue);
    }
  }

  useEffect(() => {
    getStorageValue();
  }, [getStorageValue]);

  return [storageValue, updateStorage];
};

export default useStorage;
