import { StyleSheet } from 'react-native';
import { windowHeight, windowWidth } from '../../../helpers/CommonHelper';

const styles = StyleSheet.create({
  pageArea: {
    flex: 1,
    backgroundColor: 'black',
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'black',
  },
  contentArea: {
    flex: 1,
    zIndex: 98,
  },
  railContainer: {
    position: 'absolute',
    width: windowWidth,
    height: windowHeight,
    zIndex: 98,
  },
  // listStyle: {
  //   paddingVertical: padding,
  //   paddingStart: sidebarWidth + padding,
  //   overflow: isWeb ? undefined : 'visible',
  // },
  // contentContainerStyle: {
  //   gap: itemMargin,
  //   paddingEnd: isWeb ? marginEnd : sidebarWidth + marginEnd + padding,
  // },
  // contentContainerStyleRTL: {
  //   paddingStart: isWeb ? 0 : sidebarWidth + padding,
  //   paddingEnd: marginEnd,
  //   gap: itemMargin,
  // },
  loading: {
    position: 'absolute',
    backgroundColor: 'black',
    zIndex: 99,
    start: 0,
    top: 0,
  },
});

export default styles;
