import { apiModes } from '../../../helpers/Constants';

// Enveriment Variable
const apiModeFromEnv = process.env.REACT_APP_API_MODE;

const environment = {
  name: 'production',
  apiMode: apiModeFromEnv || apiModes.test,
  baseUrls: {
    test: {
      api: 'https://navigation.tod2-test.beiniz.biz/',
      image: 'https://tod-dev.enhance.diagnal.com/',
      apiVersion: '1',
    },
    regression: {
      api: 'https://navigation.tod2-reg.beiniz.biz/',
      image: 'https://tod-preprod.enhance.diagnal.com/',
      apiVersion: '1',
    },
    live: {
      api: 'https://navigation.tod2.beiniz.biz/',
      image: 'https://tod.enhance.diagnal.com/',
      apiVersion: '1',
    },
  },
};

export default environment;
