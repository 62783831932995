import NavigationRoutes from '../navigation/NavigationRoutes';

/**
 * Menu Options
 */

const MenuOptions = [
  { id: 'login', route: NavigationRoutes.signIn },
  { id: 'home', route: NavigationRoutes.home },
  { id: 'tv-shows', route: NavigationRoutes.tvshows },
  { id: 'my-list', route: NavigationRoutes.mylist },
  { id: 'movies', route: NavigationRoutes.movies },
  { id: 'sports', route: NavigationRoutes.sports },
  { id: 'live-tv', route: NavigationRoutes.livetv },
  { id: 'profiles', route: NavigationRoutes.profiles },
  { id: 'my-account', route: NavigationRoutes.settings },
  { id: 'search', route: NavigationRoutes.search },
  { id: 'kids', route: NavigationRoutes.kids },
];

export default MenuOptions;
