/**
 * String define routes
 *
 * @typedef {(string)} NavigationRoutes
 */
const NavigationRoutes = {
  splash: '/splash',
  home: '/home',
  contentdetail: '/contentdetail',
  tvshows: '/tvshows',
  mylist: '/mylist',
  movies: '/movies',
  sports: '/sports',
  video: '/video',
  moreEpisodes: '/moreEpisodes',
  fourk: '/fourk',
  live: '/live',
  livetv: '/livetv',
  longPause: '/longPause',
  profiles: '/profiles',
  profilelogin: '/profilelogin',
  viewAll: '/viewall',
  manageprofile: '/manageprofile',
  addprofile: '/addprofile',
  modalLanguageSelection: '/modalLanguageSelection',
  delete: '/delete',
  settings: '/settings',
  signOut: '/signOut',
  search: '/search',
  landing: '/landing ',
  generalError: '/generalError',
  signIn: '/signIn',
  healthcheck: '/healthcheck',
  exit: '/exit',
  devModeLogin: '/devModeLogin',
  devMode: '/devMode',
  liveToVod: '/liveToVod',
  kids: '/kids',
};

export default NavigationRoutes;
