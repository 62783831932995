import React from 'react';
import { View } from 'react-native';
import { withFocusable } from '@digiturk/react-spatial-navigation';

import Text from '../../../atoms/Text';
import Icon from '../../../atoms/Icon';

import styleFunction from './styles';
import PropList from './proptypes';
import { GetScaledValue } from '@digiturk/screen-size';

/**
 * Basic Button With Icon Text
 *
 * @param {object} props - props
 * @param {string} props.size - size
 * @param {string} props.text - text
 * @param {string} props.textTwo - text
 * @param {boolean} props.focused - focused
 * @param {string} props.icon - icon
 * @param {boolean} props.circle - circle
 * @param {string} props.theme - theme
 * @param {string} props.themeFocused - color focus
 * @param {string} props.iconPosition - iconPosition
 * @param {object} props.themeCustom - color custom
 * @param {object} props.style - style
 * @param {object} props.textAreaStyle - textAreaStyle
 * @param {boolean} props.isSelected - textAreaStyle
 * @returns {module:JSX.Element} - JSX.Element
 */
const BasicButton = ({
  size,
  text,
  textTwo,
  focused,
  icon,
  circle,
  theme,
  themeFocused,
  themeCustom,
  style,
  textAreaStyle,
  iconPosition,
  iconWidth = 0,
  iconHeight,
  isSelected,
}) => {
  const styles = styleFunction(
    size,
    focused,
    circle,
    theme,
    themeFocused,
    themeCustom
  );

  return (
    <View style={[styles.container, style && style]}>
      <View style={[styles.justify]}>
        {icon && !iconPosition && (
          <View style={styles.iconArea}>
            <Icon icon={icon} {...styles.icon} />
          </View>
        )}
        {text ? (
          <View style={[styles.textArea, textAreaStyle]}>
            <Text text={text} style={[styles.text]} />
          </View>
        ) : (
          <></>
        )}
        {icon && iconPosition === 'right' && !iconWidth ? (
          <View style={[styles.iconArea, styles.iconRight]}>
            <Icon icon={icon} {...styles.icon} />
          </View>
        ) : (
          <></>
        )}
        {icon && iconWidth && isSelected ? (
          <View style={[styles.iconArea, styles.iconRight]}>
            <Icon
              icon={icon}
              {...styles.icon}
              width={GetScaledValue(iconWidth)}
              height={GetScaledValue(iconHeight)}
            />
          </View>
        ) : (
          <></>
        )}
        {icon && iconWidth && !isSelected ? (
          <View style={[styles.iconArea, styles.iconRight]}>
            {focused ? (
              <Icon
                icon={icon}
                {...styles.icon}
                width={GetScaledValue(iconWidth)}
                height={GetScaledValue(iconHeight)}
              />
            ) : (
              <></>
            )}
          </View>
        ) : (
          <></>
        )}
        {textTwo ? (
          <View style={styles.textTwoArea}>
            <Text text={textTwo} style={styles.textTwo} />
          </View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

BasicButton.whyDidYouRender = false;
BasicButton.propTypes = PropList;

export default withFocusable()(BasicButton);
